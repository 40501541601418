import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Observable} from "rxjs";
import {OtpConfig} from "./otp-config";
import {emptyOtpFormModel, OtpFormModel, UserCredentialsFormModel,} from "../login-form-model";
import {PropertyChangeListener} from "../../../tools/form/form-field-config";
import {LoginValidator} from "../login-validator";
import {LoginService} from "../login.service";
import {ErrorResponse} from "../../../tools/error-response";
import {AuthenticatedUser} from "../../authentication/authenticated-user";
import {AuthenticationComponent} from "../authentication-component";
import {ReleaseToggleState} from "../../../tools/release-toggles/release-toggle-state";
import {Ticker} from "../../../tools/ticker";

@Component({
  selector: "app-otp-login",
  templateUrl: "./otp.component.html",
})

export class OtpComponent extends AuthenticationComponent implements PropertyChangeListener<OtpFormModel> {

  @Input() set userCredentials(model: UserCredentialsFormModel) {
    this.formModel.username = model.username;
    this.formModel.password = model.password;
  }

  @Output() userAuthenticated = new EventEmitter<AuthenticatedUser>();
  @Output() notifyError = new EventEmitter<ErrorResponse>();
  @Output() reloadLogin = new EventEmitter<void>();

  formModel: OtpFormModel = emptyOtpFormModel();
  otpExpiresIn = 120;

  readonly formConfig = new OtpConfig(this.formModel, this, (property) => this.validationStatuses.get(property));

  constructor(
    private readonly loginValidator: LoginValidator,
    private readonly loginService: LoginService,
    ticker: Ticker) {
    super();
    ticker.registerForTicking("login-otp-expiry", {
      tick: () => {
        this.otpExpiresIn--;
        if (this.otpExpiresIn <= 0 && this.isFeature218Released()) {
          this.reloadLogin.emit();
        }
      }
    });
  }

  isFeature218Released() {
    return ReleaseToggleState.getInstance().isReleased("OTP_EXPIRY_COUNTDOWN_218");
  }

  onVerifyClick() {
    if (!this.isValid()) {
      return;
    }

    this.clearNotificationsAndShowProgress();

    this.doVerification(
      (authenticatedUser) => {
        this.userAuthenticated.emit(authenticatedUser);
      },
      (errorResponse: ErrorResponse) => {
        this.notifyError.emit(errorResponse);
      }
    );
  }

  handlePropertyChange(property: keyof OtpFormModel, newValue: any): void {
    if (property === "otp") {
      newValue = newValue.trim();
    }
    this.formModel[property] = newValue;
    this.validate(property);
  }

  validate(propertyToValidate?: keyof OtpFormModel) {
    const validationResult = this.loginValidator.validateOtp(this.formModel);
    this.validateProperties(validationResult, propertyToValidate);
  }

  authenticate(): Observable<AuthenticatedUser> {
    return this.loginService.verifyOtp({
      username: this.formModel.username,
      password: this.formModel.password.trim(),
      otp: this.formModel.otp.trim()
    });
  }
}
