import {Injectable} from "@angular/core";
import {ActivatedRoute, UrlSegment} from "@angular/router";

@Injectable()
export class CurrentRoute {

  getLastSegment(activatedRoute: ActivatedRoute): string {

    const urlSegments = activatedRoute.snapshot.url;

    if (urlSegments.length === 0) {
        throw Error("URL is empty!");
    }

    return urlSegments[urlSegments.length - 1].path;
  }

}
