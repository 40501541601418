import {HomeMeasurementStatus, MeasurementStatus} from "../found-measurement";
import {LocalizationStrings} from "../../../i18n/i18n-model";

const keys = new Map<HomeMeasurementStatus, keyof LocalizationStrings>()
  .set("WAITING_FOR_MAILING_INFO", "homeMeasurementStatusLabelWaitingForMailingInfo")
  .set("MAILING_TO_PATIENT", "homeMeasurementStatusLabelMailingToPatient")
  .set("MAILED_TO_PATIENT", "homeMeasurementStatusLabelMailedToPatient")
  .set("RETURNED_FROM_PATIENT", "homeMeasurementStatusLabelReturnedFromPatient");

export function getHomeStatusLocalizationKeyForInitiatedMeasurement(homeStatus: HomeMeasurementStatus, status: MeasurementStatus) {

  if (status === "DONE") {
    return "nurseMeasurementStatusDone";
  }

  if (status !== "INITIATED") {
    return "nurseMeasurementStatusProcessing";
  }

  return getHomeStatusLocalizationKey(homeStatus);
}

export function getHomeStatusLocalizationKey(homeStatus: HomeMeasurementStatus): keyof LocalizationStrings{
  return keys.get(homeStatus)!;
}
