import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiClient} from "../../tools/api-client";
import {FoundChain} from "./chains-model";

@Injectable()
export class ChainsService {

  constructor(private readonly apiClient: ApiClient) {
  }

  addChain(chainName: string): Observable<FoundChain> {
    return this.apiClient.post<FoundChain>("/api/chains", chainName);
  }


  getAllChains():Observable<FoundChain[]> {
    return this.apiClient.get<FoundChain[]>("/api/chains");
  }
}
