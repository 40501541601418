import {Component, OnInit} from "@angular/core";
import {ChainsService} from "./chains.service";
import {LocalizationStrings} from "../../i18n/i18n-model";
import {isChainNameValid} from "./is-chain-name-valid";
import {FoundChain} from "./chains-model";
import {RouteNavigator} from "../../tools/navigation/route-navigator.service";
import {NavigationRoute} from "../../tools/navigation/navigation-route";

@Component({
  selector: "app-chains",
  templateUrl: "./chains.component.html",
})
export class ChainsComponent implements OnInit {
  chainName = "";
  errorMessage?: keyof LocalizationStrings;
  existingChains: FoundChain[] = [];

  constructor(private readonly chainsService: ChainsService,
              private readonly routeNavigator: RouteNavigator) {
  }

  ngOnInit(): void {
    this.chainsService.getAllChains().subscribe({
      next: chains => this.existingChains = chains
    });
  }


  addChain() {
    if (this.doesChainNameExist() || !this.isChainNameValid()) {
      return;
    }
    this.chainsService
      .addChain(this.chainName)
      .subscribe(
        {
          next: (chain: FoundChain) => {
            this.existingChains.push(chain);
            this.chainName = "";
          }
        }
      );
  }

  updateName(event: Event) {
    const input = event.target as HTMLInputElement;
    this.chainName = input.value;
    const nameExists = this.doesChainNameExist();

    if (nameExists) {
      this.errorMessage = "addChainErrorChainExists";
      return;
    }

    const nameValid = this.isChainNameValid();

    this.errorMessage = nameValid
      ? undefined
      : "addChainErrorChainNameInvalid";
  }

  private doesChainNameExist(): boolean {
    return this.existingChains.map(chain=> chain.name).includes(this.chainName);
  }

  private isChainNameValid(): boolean {
    return isChainNameValid(this.chainName);
  }

  editChain(chainId: number) {
    this.routeNavigator.navigateToPath([NavigationRoute.EDIT_CHAIN, String(chainId)])
  }
}

