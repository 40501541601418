import { Component, EventEmitter, Input, Output} from "@angular/core";

export interface SelectOption {
  value?: string;
  text: string;
  localize: boolean;
}

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html"
})
export class SelectComponent {

  @Input()
    id?: string;

  @Input()
    testId?: string;

  @Input()
    value?: string;

  @Input()
    options: SelectOption[] = [];

  @Output()
    valueChanged = new EventEmitter<string>();

  @Input()
    inForm = false;

  getStyle(): string{
    return this.inForm? "form-select col" : "form-select form-select-sm col";
  }
  onChange(value: string | undefined): void {
    this.value = value;
    this.valueChanged.emit(value);
  }

}
