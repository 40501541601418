<div class="container-fluid container--available-viewport-height">
  <div class="row justify-content-md-center">
    <loading-indicator *ngIf="measurementLoadingInProgress$ | async"></loading-indicator>
  </div>

  <div class="row h-100">
    <div class="h-100 overflow-auto col-md-6 col-md">
      <div id="leftPanel" *ngIf="ecgAnalysis">
        <div class="row">
          <div id="studyId" class="col-9 row pb-4">

            <div
              class="w-100"
              *ngFor="let entry of [
                { label: labelStudyId, value: ecgAnalysis.code },
                { label: labelHospitalName, value: ecgAnalysis.hospitalName },
                { label: labelUniqueId, value: ecgAnalysis.uniqueId },
              ]"
            >
              <span class="strong ps-4">{{ entry.label | localize }}:</span>
              <span class="ps-2">{{ entry.value }}</span>
            </div>

            <div class="w-100" *ngIf="ecgAnalysis.isExternallyAnalyzed">
              <span class="strong ps-4">{{ labelExternalAnalysisUrl | localize }}:</span>
              <a data-testId="external-analysis-url" class="ps-2" *ngIf="ecgAnalysis.externalAnalysisUrl"
                 [href]="ecgAnalysis.externalAnalysisUrl"
                 target="_blank">{{ ecgAnalysis.externalAnalysisUrl }}</a>
            </div>

          </div>
          <div class="col-3 text-end">
          <app-pre-confirm-action-button
            *ngIf="canSkipReport()"
            [testId]="'skip-report-button'"
            [buttonClass]="'btn btn-outline-primary'"
            (buttonClick)="skipReport()"
            [buttonLabel]="'analysisButtonSkipReport'"
            [confirmationLabel]="'analysisButtonSkipReportConfirmation'"
            [actionInProgressLabel]="'analysisButtonSkipReportInProgress'"
            [buttonState]="skipReportState"
          ></app-pre-confirm-action-button>
          </div>
        </div>
        <div id="analysisForm">
          <form class="container form-striped">
            <app-patient-status-comments-generator
              *ngIf="isExternallyAnalyzed()"
              [analysisGenerationConfig]="ecgAnalysis.analysisGenerationConfig"
              (onGenerate)="setPatientStatusComments($event)"
              [config]="ecgFindingsFormConfig.patientStatusComments"
              [isInitiallyManualMode]="!!ecgAnalysis.doctorFindings.patientStatusComments"
            >
              <form-field [config]="ecgFindingsFormConfig.patientStatusComments"></form-field>
            </app-patient-status-comments-generator>
            <form-field
              *ngIf="!isExternallyAnalyzed()"
              [config]="ecgFindingsFormConfig.patientStatusComments"></form-field>

            <form-field
              *ngFor="let analysis of ecgSymptomAnalyses"
              [config]="analysis.ecgSymptomFindingFormConfig.finding(analysis.ecgSymptomDescription)"
            ></form-field>


            <app-findings-generator
              *ngIf="isExternallyAnalyzed()"
              [config]="ecgFindingsFormConfig.findings"
              [analysisGenerationConfig]="ecgAnalysis.analysisGenerationConfig"
              (onGenerate)="setFindings($event)"
              [measurementId]="ecgAnalysis.id"
              [isInitiallyManualMode]="!!ecgAnalysis.doctorFindings.findings"
            >
              <form-field [config]="ecgFindingsFormConfig.findings"></form-field>
            </app-findings-generator>
            <form-field
              *ngIf="!isExternallyAnalyzed()"
              [config]="ecgFindingsFormConfig.findings"></form-field>

            <form-field [config]="ecgFindingsFormConfig.conclusion"></form-field>

            <ng-container *ngIf="ecgAnalysis.type === 'HOLTER_ECG'">
              <app-diagnosis-generator
                *ngIf="isExternallyAnalyzed()"
                [config]="ecgFindingsFormConfig.diagnosis"
                [analysisGenerationConfig]="ecgAnalysis.analysisGenerationConfig"
                (onGenerate)="setDiagnosis($event)"
                [isInitiallyManualMode]="!!ecgAnalysis.doctorFindings.diagnosis"
              >
                <form-field [config]="ecgFindingsFormConfig.diagnosis"></form-field>
              </app-diagnosis-generator>
              <form-field
                *ngIf="!isExternallyAnalyzed()"
                [config]="ecgFindingsFormConfig.diagnosis"></form-field>
            </ng-container>

          </form>

          <div id="samplesAndPreview" *ngIf="!isPreviewMode">
            <div class="row" *ngIf="!ecgAnalysis.isExternallyAnalyzed">
              <div class="col-md-10 offset-md-2">
                <label class="me-4">{{ labelSamplesDocument | localize }}:</label>
                <input
                  *ngIf="!foundEcgSamplesDocument"
                  type="file"
                  accept=".pdf"
                  (change)="onEcgSamplesFileSelected(getFirstSelectedFile($event))"
                />
                <div *ngIf="foundEcgSamplesDocument" class="pt-1 d-inline-block">
                  <span class="me-2">{{ foundEcgSamplesDocument.fileName }}</span>
                  <button class="btn btn-sm btn-secondary" (click)="clearExistingEcgSamplesFile()">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
                <div *ngIf="!isEcgSamplesFileValid()" class="form-field-invalid-feedback">
                  {{ "analysisFormInvalidEcgFile" | localize }}
                </div>
              </div>
            </div>

            <div class="pt-2">
              <action-button
                type="button"
                class="btn btn-link mt-2"
                (click)="previewEcgReport()"
                [testId]="'preview-button'"
                [buttonLabel]="labelPreviewEcgReport"
                [actionInProgress]="previewInProgress"
              >
              </action-button>
            </div>
          </div>
        </div>

        <div id="saveAndFinish" *ngIf="!isPreviewMode">
          <div class="pt-4">
            <button *ngIf="!isFinishMode" type="button" class="btn btn-primary me-4" (click)="switchToFinishMode()">
              {{ labelButtonFinish | localize }}<i class="fa fa-chevron-right mx-1"></i>
            </button>

            <action-button
              *ngIf="!isFinishMode"
              class="me-4"
              buttonClass="btn btn-outline-primary"
              [buttonLabel]="labelSave"
              [actionInProgressLabel]="labelInProgress"
              (buttonClick)="onSaveButtonClick()"
              [actionInProgress]="saveInProgress$ | async"
            >
            </action-button>

            <action-button
              *ngIf="isFinishMode"
              class="me-4"
              buttonClass="btn btn-primary"
              [buttonLabel]="labelButtonFinishConfirm"
              [actionInProgressLabel]="labelButtonFinishInProgress"
              (buttonClick)="onFinishAnalysisClick()"
              [actionInProgress]="finishAnalysisInProgress$ | async"
            >
            </action-button>
            <button *ngIf="isFinishMode" type="button" class="btn btn-secondary" (click)="switchToSaveMode()">
              {{ labelButtonFinishCancel | localize }}
            </button>
            <span *ngIf="!!errorMessage" class="alert alert-danger ms-4">{{ errorMessage | localize }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="h-100 col-md-6 col-md">
      <div class="h-95 overflow-auto">
        <div
          id="patientDataPanel"
          *ngIf="patientDataFields && ecgAnalysis"
          class="col-12 small form-grid form-grid-striped"
        >
          <div class="row">
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.gender"></app-form-field-display>
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.age"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.height"></app-form-field-display>
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.weight"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.smoking"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.abnormalHeartbeats"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.slowPulse"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.fastPulse"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.palpitation"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.irregularPulse"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.dizziness"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.lossOfConsciousness"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.blackouts"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.chestPain"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.shortnessOfBreath"></app-form-field-display>
            <app-form-field-display [label]="labelStart" [value]="ecgAnalysis.startTime | appLocalDateTime"
                                    class="col-6"></app-form-field-display>
          </div>

          <div class="row">
            <app-form-field-display
              class="col-12"
              [formFieldConfig]="patientDataFields.diagnosedHeartDisease"
              [displayHorizontal]="false"
            ></app-form-field-display>
          </div>

          <div class="row">
            <app-form-field-display
              class="col-12"
              [formFieldConfig]="patientDataFields.currentMedication"
              [displayHorizontal]="false"
            ></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display
              [formFieldConfig]="patientDataFields.reasonForStudy"
              [displayHorizontal]="false"
              class="col-12"
            ></app-form-field-display>
          </div>
          <div class="row" *ngIf="isEcgHolterOrAtrialFibrillation()">
            <app-form-field-display [formFieldConfig]="patientDataFields.diary" [displayHorizontal]="false"
                                    class="col-12"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display
              [formFieldConfig]="patientDataFields.patientFeedback"
              [displayHorizontal]="false"
              class="col-12"
            ></app-form-field-display>
          </div>
        </div>
      </div>

      <div class="text-end mt-5">
        <button
          *ngIf="ecgAnalysis"
          type="button"
          [ngClass]="'btn btn-sm me-4 mb-1 ' + getButtonType()"
          data-testid="show-comments-button"
          (click)="onShowCommentsClick()"
          [title]="getCommentButtonLabel('measurementsGridButtonLabelShowComments' | localize, ecgAnalysis.commentsCount!)"
        >
          <i class="fa fa-comment me-1"></i>
          <b>{{ String(ecgAnalysis.commentsCount || "0") }}</b>
        </button>
      </div>
    </div>
  </div>
</div>


<ng-template #templateComments>
    <app-comments
      [measurementId]="ecgAnalysis!.id"
      [code]="ecgAnalysis!.code"
      [hospitalName]="ecgAnalysis!.hospitalName"
      (commentsCountChanged)="onCommentsCountChanged($event)"
      (close)="modalRefComments?.hide()"
    >
    </app-comments>
</ng-template>
