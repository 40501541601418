import {Injectable} from "@angular/core";
import {MeasurementStatus} from "../found-measurement";
import {MeasurementType} from "../../measurement-models";

@Injectable()
export class MeasurementDetailsService {

  canEditMeasurement(status: MeasurementStatus): boolean {
    return status === "UPLOADED";
  }

  canEditHolterType(type: MeasurementType, status: MeasurementStatus): boolean {

    const editableHolterTypeStatuses: MeasurementStatus[] = [
      "READY_FOR_ANALYSIS",
      "READY_FOR_POST_ANALYSIS",
    ];

    return type === "HOLTER_ECG" && editableHolterTypeStatuses.includes(status);
  }

  private readonly repeatableStatuses: MeasurementStatus[] = ["UPLOADED", "READY_FOR_ANALYSIS"];

  canRepeatMeasurement(status: MeasurementStatus, isHome: boolean): boolean {
    return isHome && this.repeatableStatuses.includes(status);
  }

  canRevertFromDone(status: MeasurementStatus, nurseDownloadedReport: boolean) {
    return status === "DONE" && !nurseDownloadedReport;
  }
}
