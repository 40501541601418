import {Component, Input} from "@angular/core";
import {NavigationRoute} from "../../tools/navigation/navigation-route";
import {localizationKey} from "../../i18n/i18n-model";
import {AuthenticatedUser} from "../authentication/authenticated-user";
import {ReleaseToggleState} from "../../tools/release-toggles/release-toggle-state";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
})
export class NavigationComponent {
  @Input()
  currentUser: AuthenticatedUser | undefined;

  isNurse(): boolean {
    return !!this.currentUser && this.currentUser.role === "NURSE";
  }

  isDataOfficer(): boolean {
    return !!this.currentUser && this.currentUser.role === "DATA_OFFICER";
  }

  protected readonly NavigationRoute = NavigationRoute;
  protected readonly localizationKey = localizationKey;

  feature397Released() {
    return ReleaseToggleState.getInstance().isReleased("ORDER_HOME_MEASUREMENT_367");
  }

  isNurseWithAllowedLocalMeasurementTypes() {
    return this.isNurse()
      && (
        this
          .currentUser!
          .nurseSettings!
          .allowedLocalMeasurementTypes
        ||
        !ReleaseToggleState
          .getInstance()
          .isReleased("HIDE_UPLOAD_INITIATE_DATA_FORMS_WHEN_NO_LOCAL_TYPES_ALLOWED_368")
      );
  }

  isNurseWithAllowedHomeMeasurementTypes() {
    return this.isNurse()
      && (
        this
          .currentUser!
          .nurseSettings!
          .allowedHomeMeasurementTypes
        ||
        !ReleaseToggleState
          .getInstance()
          .isReleased("HIDE_UPLOAD_INITIATE_DATA_FORMS_WHEN_NO_LOCAL_TYPES_ALLOWED_368")
      );

  }

  isNurseWithOperatorHospitals() {
    return this.isNurse()
      && this.currentUser!.nurseSettings!.hasOperatorHospitals;
  }
}
