<div class="py-2">
  <div class="d-flex">
    <div class="d-flex col-6">
      <span class="generator-title col-4">{{ label | localize }}</span>
      <span *ngIf="value"
            [attr.data-testId]="testId + '-badge'"
            class="badge rounded-pill bg-secondary generator-badge ms-auto me-2">{{ value }}</span>
    </div>
    <app-generator-text-field
      class="col-6"
      [testId]="testId"
      [text]="text"
      (onTextChange)="onGeneratedTextEdited($event)"
    ></app-generator-text-field>

  </div>
</div>
