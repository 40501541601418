<div data-testId="chain-details" *ngIf="chainDetails">
  <h1>{{ chainDetails.name }}</h1>

  <hr>

  <form-field [config]="formConfig.ssnEnabled"></form-field>
  <form-field [config]="formConfig.apiKey"></form-field>
  <form-field [config]="formConfig.doctorPoolsEnabled"></form-field>
  <form-field [config]="formConfig.nurseCommentsEnabled"></form-field>
  <action-button
    [testId]="'save-button'"
    [buttonLabel]="'chainDetailsSubmitButtonLabel'"
    buttonClass="btn btn-primary"
    (click)="saveChainSettings()"
    [actionInProgress]="savingInProgress"
  >
  </action-button>
  <hr>

  <h2 class="mb-3">{{ "chainDetailsHospitalListLabel" | localize }}</h2>

  <div class="mb-2" *ngFor="let hospitalName of chainDetails.hospitalNames">
    {{ hospitalName }}
  </div>


</div>
