<app-notifications></app-notifications>

<nav class="navbar navbar-expand-lg" *ngIf="showNavBarAndFooter">
  <img src="assets/logo/logo.svg" class="img-fluid logo" alt="{{ 'navigationLogoDescription' | localize }}"/>
  <div class="me-auto">
    <app-navigation
      [currentUser]="currentUser"
    ></app-navigation>
  </div>
  <div *ngIf="!!currentUser">
    <app-user-menu></app-user-menu>
  </div>
</nav>

<router-outlet></router-outlet>

<app-footer *ngIf="!!currentUser && showNavBarAndFooter"></app-footer>
