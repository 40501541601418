<div class="container-fluid container--available-viewport-height table-responsive">
  <table class="table table-striped">
    <thead class="thead-dark">
      <tr>
        <th scope="col">E-mail</th>
        <th scope="col">Full Name</th>
        <th scope="col">Role Name</th>
        <th scope="col">Hospital Name</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users">
        <td>{{ user.email }}</td>
        <td>{{ user.firstName }} {{ user.lastName }}</td>
        <td>{{ user.role }}</td>
        <td>{{ user.hospital }}</td>
        <td>
          <action-button
            buttonType="button"
            buttonClass="btn btn-sm btn-danger me-4 mb-1"
            [buttonLabel]="'userListButtonLabelResetPassword' | localize"
            [actionInProgressLabel]="'userListButtonLabelResetPasswordInProgress' | localize"
            [actionInProgress]="isResetPasswordInProgress(user)"
            (buttonClick)="onResetPasswordClick(user)"></action-button>

          <action-button
            *ngIf="!user.active"
            buttonType="button"
            buttonClass="btn btn-sm btn-info me-4 mb-1"
            [buttonLabel]="'userListButtonLabelActivateUser' | localize"
            [actionInProgressLabel]="'userListButtonLabelActivateUserInProgress' | localize"
            [actionInProgress]="isUserActiveStatusChangeInProgress(user)"
            (buttonClick)="onActivateUserClick(user)"></action-button>

          <action-button
            *ngIf="user.active"
            buttonType="button"
            buttonClass="btn btn-sm btn-danger me-4 mb-1"
            [buttonLabel]="'userListButtonLabelDeactivateUser' | localize"
            [actionInProgressLabel]="'userListButtonLabelDeactivateUserInProgress' | localize"
            [actionInProgress]="isUserActiveStatusChangeInProgress(user)"
            (buttonClick)="onDeactivateUserClick(user)"></action-button>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="userListLoadingInProgress$ | async" class="row justify-content-sm-center">
    <div class="col-sm-3 ">
      <loading-indicator></loading-indicator>
    </div>
  </div>
</div>

<ng-template #templateConfirm>
  <div class="modal-body text-center">
    <p>
      {{ "modalPromptAreYouSure" | localize }}
    </p>
    <button type="button" class="btn btn-default" (click)="handleAction()">
      {{ "modalResponseYes" | localize }}
    </button>
    <button type="button" class="btn btn-primary" (click)="modalRefConfirm?.hide()">
      {{ "modalResponseNo" | localize }}
    </button>
  </div>
</ng-template>
