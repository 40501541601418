<div>
  <input type="file" accept=".pdf" (change)="onFileSelected(getFirstSelectedFile($event))"/>
  <div class="pt-1">
    <action-button
      *ngIf="!!file"
      class="me-1"
      buttonLabel="reportUploadButtonConfirmLabel"
      actionInProgressLabel="reportUploadButtonConfirmInProgressLabel"
      (buttonClick)="onUploadButtonClick()"
      [actionInProgress]="uploadInProgress$ | async"
    >
    </action-button>
    <button
      type="button"
      class="btn btn-secondary btn-sm"
      (click)="onCancelButtonClick()"
    >
      <i class="fa fa-remove"></i>
    </button>
  </div>
</div>
