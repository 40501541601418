<div class="container-fluid container--available-viewport-height table-responsive" *ngIf="hospitalDetails">
  <div class="row justify-content-sm-center mb-3">
    <h5 data-testid="hospital-name">{{ hospitalDetails.name }}</h5>
  </div>

  <div class="row justify-content-center">
    <div class="col-6">
      <div class="row">
        <h5>{{ localizationKey('editHospitalOperatorHospitals') | localize }}</h5>
      </div>
      <div class="row mb-3">
        <span>{{ hospitalDetails.name }}{{ localizationKey('editHospitalChooseOperatorText') | localize }} </span>
        <hr/>
      </div>
      <div class="grid grid-striped" data-testId="hospital-list">
        <div *ngFor="let operatorHospital of hospitalDetails.operatorHospitals" class="row" data-testid='hospital'>
          <div class="col-11"><span>{{ operatorHospital.name }}</span></div>
          <div class="col-1">
            <button class="btn btn-sm btn-danger" [attr.data-testid]='"remove-operator-button-" + operatorHospital.name'
                    (click)="removeOperator(operatorHospital)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-11">
          <select [(ngModel)]="selectedEligibleOperator" data-testId="eligible-hospital-dropdown"
                  class="form-select col-sm-auto ">
            <option *ngFor="let eligibleOperator of eligibleOperators"
                    [ngValue]="eligibleOperator?.id">{{ eligibleOperator?.name }}
            </option>
          </select>
        </div>
        <div class="col-1">
          <button data-testId="add-button" class="form-control col-sm-auto btn btn-sm btn-primary"
                  (click)="addOperator()"
                  [disabled]="selectedEligibleOperator===undefined || selectedEligibleOperator===null">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr/>
  <app-allowed-types-section></app-allowed-types-section>
  <hr/>
  <app-nurse-settings-section></app-nurse-settings-section>

  <hr/>
  <div class="row justify-content-center">
    <div class="col-6">
      <div class="row">
        <h5>{{ localizationKey('hospitalInformationTitle') | localize }}</h5>
      </div>

      <form-field [config]="hospitalDetailsFormConfig!.publicName"></form-field>
      <form-field [config]="hospitalDetailsFormConfig!.operatorAppointmentDescription"></form-field>
      <form-field [config]="hospitalDetailsFormConfig!.address"></form-field>
      <form-field [config]="hospitalDetailsFormConfig!.postalCode"></form-field>
      <form-field [config]="hospitalDetailsFormConfig!.city"></form-field>

      <div>
        <button class="btn btn-sm btn-primary"
                data-testId="hospital-information-submit-button"
                (click)="submitHospitalInformation()"
                [disabled]="isHospitalInformationButtonDisabled()"
        >
          {{ localizationKey('hospitalInformationUpdateButton') | localize }}
        </button>
      </div>
    </div>

  </div>

  <hr/>
  <div class="row justify-content-center">
    <div class="col-6">
      <div class="row">
        <h5>{{ localizationKey('hospitalPatientFormTemplatesTitle') | localize }}</h5>
      </div>
      <div class="grid grid-striped">
        <div
          *ngFor="let patientFormTemplateOptionsForMeasurementType of hospitalDetails.patientFormTemplateOptionsByMeasurementType | keyvalue"
          class="row">
          <div class="col-6">
            <label
              [attr.for]="getPatientFormTemplateSelectId(patientFormTemplateOptionsForMeasurementType.key)">
              {{ getMeasurementTypeLocalizationKey(patientFormTemplateOptionsForMeasurementType.key) | localize }}
            </label>
          </div>
          <div class="col-6">

            <select [attr.id]="getPatientFormTemplateSelectId(patientFormTemplateOptionsForMeasurementType.key)"
                    [attr.data-testId]="patientFormTemplateOptionsForMeasurementType.key + '-patient-form-template-select'"
                    [ngModel]="hospitalDetails.selectedPatientFormTemplates.get(patientFormTemplateOptionsForMeasurementType.key)"
                    (ngModelChange)="hospitalDetails.selectedPatientFormTemplates.set(patientFormTemplateOptionsForMeasurementType.key, $event)"
                    class="form-select col-sm-auto ">
              <option *ngFor="let typeOption of patientFormTemplateOptionsForMeasurementType.value"
                      [ngValue]="typeOption">{{ typeOption }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <button class="btn btn-sm btn-primary"
                data-testid="hospital-patient-form-templates-submit-button"
                (click)="submitHospitalPatientFormTemplates()">
          {{ localizationKey('hospitalPatientFormTemplatesButton') | localize }}
        </button>
      </div>
    </div>
  </div>

  <hr/>
  <div class="row justify-content-center">
    <div class="col-6">
      <div class="row">
        <h5>{{ localizationKey('hospitalLanguageSettingsTitle') | localize }}</h5>
      </div>

      <div class="grid grid-striped">
        <div class="row">
          <div class="col-12">
            <label class="w-100">
              <input
                type="checkbox"
                data-testid="report-language-toggle"
                [(ngModel)]="hospitalDetails.reportLanguageEnabled"
              />
              {{ localizationKey('hospitalReportLanguageToggleCheckbox') | localize }}
            </label>
          </div>
        </div>

        <div class="row">

          <label class="col-6"
                 for="languageSelector">
            {{ localizationKey('hospitalLanguageSelect') | localize }}
          </label>

          <app-select class="col-6"
                      [id]="'languageSelector'"
                      [inForm]="true"
                      [value]="hospitalDetails.language"
                      [options]="languageSelectionOptions"
                      [testId]="'hospital-language-select'"
                      (valueChanged)="handleLanguageChange($event)"
          ></app-select>
        </div>
      </div>

      <div class="pt-1">
        <button class="btn btn-sm btn-primary"
                data-testId="language-settings-submit-button"
                (click)="submitHospitalLanguageSettings()"
        >
          {{ localizationKey('hospitalLanguageSettingsUpdateButton') | localize }}
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="putHospitalInChain280Released()">
    <hr/>
    <div class="justify-content-center">
      <app-chain-section
        [chains]="hospitalDetails.chains"
        [hospitalId]="hospitalDetails.id"
        [hospitalChain]="hospitalDetails.hospitalChain"
      ></app-chain-section>
    </div>
  </ng-container>

</div>
