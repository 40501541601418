<i
  *ngIf="showNurseDownloadedReport() && hasReport()"
  data-testid="new-report-indicator"
  class="text-warning fa fa-exclamation-circle fa-2x align-middle"
  [title]="'measurementsGridTitleNewReport' | localize"></i>
<a
  *ngIf="hasReport()"
  data-testid="download-report"
  target="report.pdf"
  class="btn btn-sm btn-success ms-2 me-2"
  [href]="getDownloadUrl('PRIMARY')"
  [title]="'measurementsGridButtonTitleDownloadReport' | localize"
  (click)="markReportDownloadedForNurse()">
  <i class="fa fa-download"></i>
</a>
