<div class="container-fluid container--available-viewport-min-height">
  <div class="row">
    <div class="col-sm col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
      <form #formNewUser id="newUserForm" ngNativeValidate autocomplete="off" (submit)="submit()">
        <div class="form-group">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ "newUsersLabelHospitalName" | localize }}</span>
            </div>
            <input
              name="hospitalName"
              [typeaheadScrollable]="true"
              [typeaheadOptionsInScrollableView]="7"
              [typeaheadMinLength]="0"
              [typeahead]="hospitalSuggestions$"
              [typeaheadAsync]="true"
              class="form-control"
              [(ngModel)]="selectedHospital"
              required
            />
            <div *ngIf="!hospitalNameValid" class="form-field-invalid-feedback">
              {{ "invalidHospitalNameLength" | localize }}
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ "newUsersLabelEmailAddresses" | localize }}</span>
            </div>
            <textarea
              id="userEmailsElement"
              class="form-control"
              name="userEmailsRaw"
              placeholder="{{ 'newUsersPlaceholderEmailAddresses' | localize }}"
              aria-describedby="emailHelp"
              [(ngModel)]="userEmailsRaw"
              required
              [rows]="10"
            ></textarea>
            <div *ngIf="invalidEmail" class="form-field-invalid-feedback">
              {{ "newUsersInvalidLabelEmailAddresses" | localize }}: "{{ invalidEmail }}".
            </div>
            <div *ngIf="invalidEmailLength" class="form-field-invalid-feedback">
              {{ "newUsersInvalidLabelEmailAddressesLength" | localize }}: "{{ invalidEmailLength }}".
            </div>
          </div>
        </div>
        <action-button
          buttonClass="btn btn-outline-primary"
          buttonLabel="newUsersAddButtonLabel"
          actionInProgressLabel="newUsersAddButtonLoadingLabel"
          [actionInProgress]="addNursesInProgress$ | async"
        ></action-button>
      </form>
    </div>
  </div>
</div>

<ng-template #confirmSubmitTemplate>
  <div class="modal-body text-center">
    <p>
      {{ "newUsersQuestionConfirmSubmit" | localize }} <strong class="text-nowrap">"{{ selectedHospital }}"</strong>?
    </p>
    <ul class="text-start">
      <li *ngFor="let userEmail of userEmails">
        {{ userEmail }}
      </li>
    </ul>
    <button type="button" class="btn btn-default" (click)="confirm()">{{ "modalResponseYes" | localize }}</button>
    <button type="button" class="btn btn-primary" (click)="decline()">{{ "modalResponseNo" | localize }}</button>
  </div>
</ng-template>
