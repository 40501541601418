<div class="row justify-content-center" data-testId="hospital-chain-section">
  <div class="col-6">
    <div>
      <h5>{{ localizationKey('hospitalPutHospitalInChainTitle') | localize }}</h5>
    </div>
    <div class="row">

      <label class="col-6">
        {{ localizationKey('hospitalPutHospitalInChainSelect') | localize }}:
      </label>

      <div class="col-6" *ngIf="hospitalChain === undefined">
        <select
          [(ngModel)]="selectedChainId"
          data-testId="hospital-chain-section-chains-dropdown"
          class="form-select col-sm-auto">
          <option [ngValue]="undefined"></option>
          <option *ngFor="let chain of chains"
                  [ngValue]="chain?.id">{{ chain?.name }}
          </option>
        </select>
      </div>

      <div class="col-6" *ngIf="hospitalChain !== undefined">
        <span
          class="form-control"
          data-testId="hospital-chain-section-chain-label"
        >{{ hospitalChain.name }}</span>
      </div>

    </div>
    <div class="mt-1">
      <button class="btn btn-sm btn-primary"
              data-testId="hospital-chain-section-update-button"
              (click)="putHospitalInChain()"
              [disabled]="!canPutHospitalInChain()"
      >
        {{ localizationKey('hospitalPutHospitalInChainButton') | localize }}
      </button>
    </div>

  </div>
</div>
