import {Component, Input, TemplateRef, ViewChild} from "@angular/core";
import {AuthenticatedUser} from "../../../authentication/authenticated-user";
import {ReleaseToggleState} from "../../../../tools/release-toggles/release-toggle-state";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ListItem} from "../../list-item";
import {getCommentButtonLabel} from "../../../comments/comments.utils";
import {CommentsCountChangedEvent} from "../../../comments/comments.component";

@Component({
  selector: "app-comments-button",
  templateUrl: "./comments-button.component.html",
})
export class CommentsButtonComponent {

  @Input()
  user!: AuthenticatedUser;
  @Input()
  item!: ListItem;

  feature419Released = ReleaseToggleState.getInstance().isReleased("HMO_CAN_COMMENT_419");

  modalRef: BsModalRef | undefined;
  @ViewChild("templateComments") templateComments!: TemplateRef<any>;


  constructor(
    private readonly modalService: BsModalService,
  ) {
  }


  shouldShowCommentsButton() {
    return this.user.role === "DATA_OFFICER"
      || this.user.role === "DOCTOR"
      || (this.user.role === "NURSE" && this.user.nurseSettings?.allowedComments)
      || (this.user.role === "HMO" && this.feature419Released);
  }

  showComments() {
    this.modalRef = this.modalService.show(this.templateComments, {
      class: "modal-lg"
    });
  }

  getCommentsButtonType() {
    if (this.item.foundMeasurement.commentCount === 0 || this.item.foundMeasurement.commentCount === undefined) {
      return "btn-secondary";
    } else {
      return "btn-primary";
    }
  }

  commentsCountChanged(commentsCountChangedEvent: CommentsCountChangedEvent) {
    this.item.foundMeasurement = {
      ...this.item.foundMeasurement,
      commentCount: commentsCountChangedEvent.newCommentCount
    };
  }

  protected readonly getCommentButtonLabel = getCommentButtonLabel;
  protected readonly String = String;
}
