import {LocalizationStrings} from "../../i18n/i18n-model";
import {formatDateTime} from "../date-time-utils";
import {FileOptions, SelectionOptions} from "./form-field.component";

export interface FormFieldConfig<T> {
  readonly type: FormFieldType;
  readonly property: keyof T;
  readonly label: keyof LocalizationStrings;
  readonly invalidLabel?: keyof LocalizationStrings;
  readonly infoLabel?: keyof LocalizationStrings;
  readonly required?: boolean;
  selectionOptions?: SelectionOptions<any>;
  readonly fileOptions?: FileOptions;
  readonly model: T;
  readonly propertyChangeListener?: PropertyChangeListener<T>;
  readonly isValid: () => boolean | undefined;
  readonly alignLabelRight?: boolean;
  readonly verticalLayout?: boolean;
  readonly readOnly?: boolean;
  readonly filledByNurseOnly?: boolean;
  readonly autoexpand?: boolean;
}

export type FormFieldType =
  | "text"
  | "number"
  | "file"
  | "dropdown"
  | "radio"
  | "header"
  | "textarea"
  | "password"
  | "wrapper"
  | "localdate"
  | "checkbox"
  | "positiveWholeNumber";

export interface PropertyChangeListener<T> {
  handlePropertyChange(property: keyof T, newValue: any): void;
}

export function translateFormValue<T>(formFieldConfig: FormFieldConfig<any>,
  formValue: any,
  translate: (key: keyof LocalizationStrings) => string
): string {
  if (formValue === undefined || formValue === null) {
    return "";
  }

  if (formFieldConfig.type === "localdate") {
    return formatDateTime(formValue);
  }

  if (formFieldConfig.type === "dropdown" || formFieldConfig.type === "radio") {
    const option = formFieldConfig.selectionOptions!.items.find((item) => item.value === formValue);

    return !!option ? translate(option.text as keyof LocalizationStrings) : "";
  }


  return formValue.toString();
}
