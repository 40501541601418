import {Component, OnInit} from "@angular/core";
import {ChainIdProvider} from "./chain-id-provider";
import {ActivatedRoute} from "@angular/router";
import {ChainDetailsService} from "./chain-details.service";
import {ChainDetails, ChainSettings} from "../chains-model";
import {FormConfigBase} from "../../../tools/form/form-config-base";
import {FormFieldConfig} from "../../../tools/form/form-field-config";
import {finalize} from "rxjs/operators";
import {Notifications, NotificationType} from "../../../tools/notifications/notifications";

class ChainDetailsFormConfig extends FormConfigBase<ChainSettings> {
  readonly ssnEnabled: FormFieldConfig<ChainSettings>;
  readonly apiKey: FormFieldConfig<ChainSettings>;
  readonly doctorPoolsEnabled: FormFieldConfig<ChainSettings>;
  readonly nurseCommentsEnabled: FormFieldConfig<ChainSettings>;

  constructor(settings: ChainSettings) {
    super(
      settings,
      {
        handlePropertyChange(property: keyof ChainSettings, newValue: any) {
          (settings as any)[property] = newValue;
        }
      },
      () => undefined
    );

    this.ssnEnabled = this.createField({
      property: "ssnEnabled",
      label: "chainDetailsSsnEnabledLabel",
      type: "checkbox"
    });

    this.apiKey = this.createField({
      property: "apiKey",
      label: "chainDetailsApiKeyLabel",
      type: "text",
    });

    this.doctorPoolsEnabled = this.createField({
      property: "doctorPoolsEnabled",
      label: "chainDetailsDoctorPoolsEnabledLabel",
      type: "checkbox"
    });

    this.nurseCommentsEnabled = this.createField({
      property: "nurseCommentsEnabled",
      label: "chainDetailsNurseCommentsEnabledLabel",
      type: "checkbox"
    });
  }
}

@Component({
  selector: "app-chain-details",
  templateUrl: "./chain-details.component.html",
})
export class ChainDetailsComponent implements OnInit {
  chainDetails?: ChainDetails;

  formConfig!: ChainDetailsFormConfig;
  savingInProgress = false;

  constructor(
    private readonly chainIdProvider: ChainIdProvider,
    private readonly route: ActivatedRoute,
    private readonly chainDetailsService: ChainDetailsService,
    private readonly notifications: Notifications
  ) {
  }

  ngOnInit(): void {
    const chainId = this.chainIdProvider.getId(this.route);

    if (chainId === undefined) {
      return;
    }

    this.chainDetailsService.getDetails(chainId).subscribe({
      next: (details) => {
        this.chainDetails = details;
        this.formConfig = new ChainDetailsFormConfig(this.chainDetails.settings);
      }
    });
  }

  saveChainSettings() {

    this.savingInProgress = true;

    this.chainDetailsService
      .saveSettings(this.chainDetails!.id, this.chainDetails!.settings)
      .pipe(finalize(() => this.savingInProgress = false))
      .subscribe({
        next: () => this.notifications.addNotification(NotificationType.OK, "chainDetailsSubmitSuccessMessage"),
        error: () => this.notifications.addNotification(NotificationType.ERROR, "chainDetailsSubmitErrorMessage")
      });
  }
}
