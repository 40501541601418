<div class="container position-relative m-2 w-100">
  <button type="button" data-testId="close-comments-button" class="btn-close close pull-right" aria-label="Close" (click)="close.emit()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
  <div class="text-center">
    <h3>Comments</h3>
    <div>{{ "measurementsGridColumnLabelCode" | localize }}: {{ code }}</div>
    <div>{{ "measurementsGridColumnLabelHospitalName" | localize }}: {{ hospitalName }}</div>
  </div>
</div>
<div class="modal-body overflow-auto scroll-to-bottom" style="height: 50vh">
  <div class="form-grid form-grid-striped text-start">
    <div *ngFor="let comment of comments" class="row col-12">
      <div class=" pl-3" data-testId="comment-item">
        <div><b>{{ comment.authorUsername }}</b> {{ comment.time | appLocalDateTime }}</div>
        <div>{{ comment.text }}</div>
      </div>
    </div>
  </div>
</div>
<div class="m-2 text-end">
  <div class="mt-3 mb-3">
    <textarea data-testid="add-comment-input" class=" form-control" [(ngModel)]="commentInput"> </textarea>
  </div>
  <button class="btn btn-primary" data-testid="add-comment-button" (click)="add()">Comment</button>
</div>

