import {Component, OnInit} from "@angular/core";
import {finalize, flatMap, map} from "rxjs/operators";
import {localizationKey} from "../../i18n/i18n-model";
import {AuthenticatedUser} from "../authentication/authenticated-user";
import {AuthenticationService} from "../authentication/authentication.service";
import {ListService} from "./list.service";
import {BehaviorSubject, Observable, of, ReplaySubject} from "rxjs";
import {TimeFilterSearchClick} from "../user-list/time-filter/time-filter.component";
import {ClockService} from "../../tools/time/clock.service";
import {ColumnFilter, FilterColumn} from "./column-filter/column-filter";
import {SelectOption} from "../../tools/select/select.component";
import {LocalStorageService} from "../../tools/local-storage.service";
import {NurseHospital} from "./nurseHospital";
import {DateTime} from "luxon";
import {ListItem} from "./list-item";
import {emptyListItem} from "./list-item/list-item.component";
import {ColumnVisibility, createColumnVisibility} from "./create-column-visibility";
import {environment} from "../../../environments/environment";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
})
export class ListComponent implements OnInit {

  static FILTER_FROM_DATE_LOCAL_STORAGE_KEY = "filterFromDateLocalStorageKey";
  static FILTER_TO_DATE_LOCAL_STORAGE_KEY = "filterToDateLocalStorageKey";
  static COLUMN_FILTER_LOCAL_STORAGE_KEY = "columnFilterLocalStorageKey";
  static FILTER_LAST_WRITE_TIME_KEY = "filterLastWriteTimeKey";


  unfilteredItems: ListItem[] = [];
  items: ListItem[] = [];
  user: AuthenticatedUser | undefined;

  initialFromDate?: DateTime;
  initialToDate?: DateTime;

  readonly listLoadingInProgress = new BehaviorSubject<boolean>(true);
  readonly listLoadingInProgress$: Observable<boolean> = this.listLoadingInProgress.asObservable();

  columnVisibility: ColumnVisibility = {} as ColumnVisibility;

  private columnFilter: ColumnFilter;
  filterOptions = new Map<FilterColumn, SelectOption[]>();
  filterMap = new Map<FilterColumn, string>();
  isOperator?: boolean;

  constructor(
    private readonly listService: ListService,
    authenticationService: AuthenticationService,
    private readonly clockService: ClockService,
    private readonly localStorageService: LocalStorageService,
  ) {
    this.user = authenticationService.getCurrentAuthenticatedUser();
    this.columnFilter = new ColumnFilter(this.user!);

  }

  ngOnInit() {
    this.loadTimeFilterFromLocalStorageOrSetDefaults();
    this.getHospitalIfNurse()
      .pipe(
        map((hospital) => {
          return hospital || {isOperator: false, name: "any", isChain: false};
        }))
      .pipe(map((hospital) => {
        this.isOperator = hospital?.isOperator;
        this.columnVisibility = createColumnVisibility(
          {
            role: this.user!.role,
            isOperator: this.isOperator || false,
            isChainDoctor: this.user!.isChainDoctor,
          }
        );
      }))
      .pipe(flatMap(() => this.listMeasurementsBetween(this.initialFromDate!, this.initialToDate!)))
      .subscribe(() => {
        if (!this.wasFilterSetToday()) {
          return;
        }

        this.loadColumnFiltersFromLocalStorage();
        this.filterMeasurements();
      });
  }

  handleOnSearchClick(fromToDates: TimeFilterSearchClick) {
    this.items = [];
    this.listLoadingInProgress.next(true);
    this.localStorageService.set(
      this.addUsernameUnderscoreAsPrefix(ListComponent.FILTER_FROM_DATE_LOCAL_STORAGE_KEY),
      fromToDates.from!.toUTC().toISO()!
    );
    this.localStorageService.set(
      this.addUsernameUnderscoreAsPrefix(ListComponent.FILTER_TO_DATE_LOCAL_STORAGE_KEY),
      fromToDates.to!.toUTC().toISO()!
    );

    this.setFilterDefaults();

    this.saveColumnFiltersMapToLocalStorageAsJson();

    this.listMeasurementsBetween(fromToDates.from!, fromToDates.to!);
  }

  private listMeasurementsBetween(from: DateTime, to: DateTime) {

    const onLoadedMeasurementSubject = new ReplaySubject(1);

    this.listService
      .getMeasurementsBetween(from.startOf("day"), to.endOf("day"))
      .pipe(finalize(() => this.listLoadingInProgress.next(false)))
      .pipe(finalize(() => onLoadedMeasurementSubject.next({})))
      .subscribe((items) => {
        this.unfilteredItems = items.map(foundMeasurement => ({
          ...emptyListItem(),
          foundMeasurement: foundMeasurement
        }));
        this.items = this.unfilteredItems;
        this.refreshFilterOptions();
        this.filterMeasurements();
      });

    return onLoadedMeasurementSubject;
  }

  handleFilterChange(column: FilterColumn, value: string) {
    if (value === undefined) {
      this.filterMap.delete(column);
    } else {
      this.filterMap.set(column, value);
    }

    this.saveColumnFiltersMapToLocalStorageAsJson();
    this.filterMeasurements();
  }

  private filterMeasurements() {
    this.items = this.columnFilter
      .filter(this.unfilteredItems, this.filterMap);
  }

  private refreshFilterOptions() {
    /*
    Only adding to options for column filters here, because:
      1. Setting the options map directly resets the currently selected option in the view
      2. Makes things simpler because selected option cannot be removed from options
     */
    this.columnFilter.getOptions(this.unfilteredItems).forEach((newOptions, k) => {
        const currentOptions = this.filterOptions.get(k);
        if (!currentOptions) {
          this.filterOptions.set(k, newOptions);
          return;
        }

        const currentOptionsValues = new Set(currentOptions.map(option => option.value));

        const newOptionsMinusCurrentOptions = newOptions
          .filter(x => !currentOptionsValues.has(x.value));
        currentOptions.push(...(Array.from(newOptionsMinusCurrentOptions)));
      }
    );
  }

  private loadTimeFilterFromLocalStorageOrSetDefaults() {
    const fromDate = this.localStorageService.get(this.addUsernameUnderscoreAsPrefix(ListComponent.FILTER_FROM_DATE_LOCAL_STORAGE_KEY));
    const toDate = this.localStorageService.get(this.addUsernameUnderscoreAsPrefix(ListComponent.FILTER_TO_DATE_LOCAL_STORAGE_KEY));

    if (fromDate && toDate && this.wasFilterSetToday()) {
      this.initialToDate = DateTime.fromISO(toDate);
      this.initialFromDate = DateTime.fromISO(fromDate);
    } else {
      const intervalInDays =
        this.user?.role === "NURSE"
          ? environment.defaultListIntervalDaysForNurse
          : environment.defaultListIntervalDays;
      this.initialToDate = this.clockService.now().endOf("day");
      this.initialFromDate = this.initialToDate.minus({days: intervalInDays}).startOf("day");
    }
  }

  private wasFilterSetToday() {
    const lastFilterWriteTimeString = this.localStorageService
      .get(this.addUsernameUnderscoreAsPrefix(ListComponent.FILTER_LAST_WRITE_TIME_KEY));
    const now = this.clockService.now();
    // @ts-ignore
    const lastFilterWriteDateTime = DateTime.fromISO(lastFilterWriteTimeString);
    return lastFilterWriteTimeString !== undefined
      && now > lastFilterWriteDateTime.startOf("day")
      && now < lastFilterWriteDateTime.endOf("day");
  }

  private loadColumnFiltersFromLocalStorage() {
    const filterMapJson = this.localStorageService.get(this.addUsernameUnderscoreAsPrefix(ListComponent.COLUMN_FILTER_LOCAL_STORAGE_KEY));
    if (filterMapJson !== undefined) {
      this.filterMap = new Map<FilterColumn, string>(JSON.parse(filterMapJson));
    }
  }

  private getHospitalIfNurse(): Observable<NurseHospital | undefined> {
    if (this.user?.role === "NURSE") {
      return this.listService.getNurseHospital();
    }

    return of(undefined);
  }

  private saveColumnFiltersMapToLocalStorageAsJson() {
    this.localStorageService.set(
      this.addUsernameUnderscoreAsPrefix(ListComponent.FILTER_LAST_WRITE_TIME_KEY),
      this.clockService.now().toUTC().toISO()!
    );
    this.localStorageService.set(
      this.addUsernameUnderscoreAsPrefix(ListComponent.COLUMN_FILTER_LOCAL_STORAGE_KEY),
      JSON.stringify(Array.from(this.filterMap.entries()))
    );
  }

  private addUsernameUnderscoreAsPrefix(string: string) {
    return `${this.user?.username}_${string}`;
  }

  isStatusFilterVisible() {
    return this.user!.role !== "DOCTOR" || this.user!.isChainDoctor;
  }

  private setFilterDefaults() {
    this.filterMap.clear();
  }

  getTotalRowsCount(): number {
    return this.unfilteredItems.filter(it => !it.foundMeasurement.deleted).length;
  }

  protected readonly localizationKey = localizationKey;

  onMeasurementChanged() {
    this.filterMeasurements();
    this.refreshFilterOptions();
  }
}
