import {Injectable} from "@angular/core";

@Injectable()
export class ReloadApp {

  reload() {
    const url = new URL(window.location.href);
    url.searchParams.set("nocache", `${Date.now()}`);
    window.history.replaceState(null, "", url.toString());
    window.location.reload();
  }
}
