import {Injectable} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

@Injectable()
export class ChainIdProvider {
  constructor() {}

  getId(route: ActivatedRoute): number | undefined {
    const idParam = route.snapshot.paramMap.get("id");

    if (idParam === null || isNaN(+idParam)) {
      return undefined;
    }

    return +idParam;
  }
}
