import { Component, OnInit } from "@angular/core";
import { FormConfigBase } from "../../../tools/form/form-config-base";
import { RouteNavigator } from "../../../tools/navigation/route-navigator.service";
import { NavigationRoute } from "../../../tools/navigation/navigation-route";
import { Notifications, NotificationType } from "../../../tools/notifications/notifications";
import { ForgotPasswordService } from "./forgot-password.service";
import { finalize } from "rxjs/operators";
import { FormValidator } from "../../../tools/form/form-validator";
import { isValidEmail } from "../../validations";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent implements OnInit {

  readonly formConfig!: ForgotPasswordFormConfig;
  private model: ForgotPasswordModel;
  resetInProgress = false;

  readonly formValidator: FormValidator<ForgotPasswordModel>;

  constructor(
    private readonly routeNavigator: RouteNavigator,
    private readonly notifications: Notifications,
    private readonly forgotPasswordService: ForgotPasswordService
  ) {

    this.model = { username: "" };

    this.formValidator = new FormValidator(
      this.model,
      new Map()
      .set("username", (username: string) => isValidEmail(username))
    );

    this.formConfig = new ForgotPasswordFormConfig(
      this.model,
      {
        handlePropertyChange: (property: keyof ForgotPasswordModel, newValue: any) => {
          this.model[property] = newValue;
          this.formValidator.updateAllValidations();
        }
      },
      (property) => this.formValidator.isPropertyValid(property),
    );
  }

  ngOnInit(): void {
  }

  handleResetClick() {

    if(!this.formValidator.isFormValid()) {
      return;
    }

    this.resetInProgress = true;
    this.forgotPasswordService.resetForgottenPassword(this.model.username)
    .pipe(finalize(() => this.resetInProgress = false))
    .subscribe({
      next: () => {
        this.notifications.addNotification(NotificationType.OK, "resetForgottenPasswordMessage");
        this.routeNavigator.navigateTo(NavigationRoute.LOGIN);
      }
    });
  }
}

interface ForgotPasswordModel {
  username: string;
}

class ForgotPasswordFormConfig extends FormConfigBase<ForgotPasswordModel> {

  readonly username = this.createField({
    label: "forgotPasswordFormUsernameLabel",
    property: "username",
    type: "text"
  });

}
