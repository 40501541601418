export const ALL_RELEASE_TOGGLE_NAMES = [
  "REPORT_LANGUAGE_141",
  "IMPROVED_LIST_STYLE",
  "DO_UPLOADS_INITIATED_192",
  "GENERATE_PATIENT_STATUS_COMMENTS_227",
  "GENERATE_FINDINGS_233",
  "GENERATE_DIAGNOSIS_246",
  "FINDINGS_GENERATOR_VALUES_OUTDATED_247",
  "ADD_LESS_THAN_0_1_RULES_268",
  "VENTRICULAR_TACHYCARDIA_FINDINGS_FIELD_267",
  "UPLOAD_FORM_TEXT_AREAS_AUTO_EXPANDING_263",
  "ADD_NEW_ATRIAL_TACHYCARDIAS_RULES_269",
  "PATIENT_ID_MUST_NOT_CONTAIN_SSN_241",
  "TRIM_SPACES_ON_PASSWORD_CHANGE_265",
  "FORMAT_FINDINGS_VALUES_271",
  "NURSE_DELETES_INITIATED_MEASUREMENT_277",
  "EDIT_HOLTER_TYPE_16",
  "REASSIGN_DOCTOR_276",
  "SHOW_SYMPTOM_CHARACTER_COUNTER_TO_NURSES_297",
  "PUT_HOSPITAL_IN_CHAIN_280",
  "E_FORM_LANGUAGE_SELECTOR_303",
  "NEW_E_FORM_LANDING_PAGE_DESIGN_304",
  "ASSIGN_TO_CHAIN_282",
  "UPDATE_RULES_333",
  "HOME_HOLTER_PILOT_340",
  "HOME_HOLTER_LENGTH_342",
  "ENABLE_HOME_MEASUREMENT_TYPES_311",
  "HOME_MEASUREMENT_E_FORM_315",
  "ORDER_HOME_MEASUREMENT_367",
  "ORDERED_STATUS_IN_NURSE_LIST_370",
  "COPY_HOME_MEASUREMENT_INSTRUCTIONS_373",
  "DOWNLOAD_HOME_MEASUREMENT_INSTRUCTIONS_395",
  "HOME_STATUS_FOR_NURSE_AND_DO_319",
  "HIDE_UPLOAD_INITIATE_DATA_FORMS_WHEN_NO_LOCAL_TYPES_ALLOWED_368",
  "SKIP_REPORT_411",
  "E-FORM_CODE_IN_URL_397",
  "USER_RESETS_PASSWORD_359",
  "OTP_EXPIRY_COUNTDOWN_218",
  "REPEAT_MEASUREMENT_379",
  "REVERT_FROM_DONE_18",
  "HMO_CAN_COMMENT_419"
] as const;
type ReleaseToggleNameTuple = typeof ALL_RELEASE_TOGGLE_NAMES;
export type ReleaseToggleName = ReleaseToggleNameTuple[number];

export interface ReleaseToggle {
  name: ReleaseToggleName;
  released: boolean;
}

export class ReleaseToggleState {

  private static instance: ReleaseToggleState | undefined = undefined;

  public static getInstance(): ReleaseToggleState {
    if (ReleaseToggleState.instance === undefined) {
      ReleaseToggleState.instance = new ReleaseToggleState();
    }

    return ReleaseToggleState.instance;
  }

  private readonly toggles: Map<ReleaseToggleName, boolean> = new Map();

  public setToggles(toggles: ReleaseToggle[]) {
    this.toggles.clear();
    toggles.forEach(toggle => this.toggles.set(toggle.name, toggle.released));
  }

  public isReleased(name: ReleaseToggleName): boolean {
    return this.toggles.get(name) || false;
  }

  public turnOff(name: ReleaseToggleName) {
    this.toggles.set(name, false);
  }

  public select<T>(name: ReleaseToggleName, valueIfReleased: T, valueIfNotReleased: T): T {
    return this.isReleased(name)
      ? valueIfReleased
      : valueIfNotReleased;
  }
}
