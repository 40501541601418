<div data-testid="form-SLEEP">
  <div
    data-testid="pre-measurement-form-SLEEP"
    *ngIf="showPreMeasurementFields"
  >
    <form-field [config]="formConfig.reasonForStudy"></form-field>
    <form-field [config]="formConfig.gender"></form-field>
    <form-field [config]="formConfig.age"></form-field>
    <form-field [config]="formConfig.height"></form-field>
    <form-field [config]="formConfig.weight"></form-field>
    <form-field [config]="formConfig.overweight"></form-field>
    <form-field [config]="formConfig.profession"></form-field>

    <hr/>

    <form-field [config]="formConfig.hypertension"></form-field>
    <form-field [config]="formConfig.diabetes"></form-field>
    <form-field [config]="formConfig.overbite"></form-field>
    <form-field [config]="formConfig.cloggedNasalPassage"></form-field>
    <form-field [config]="formConfig.allergicRhinitis"></form-field>
    <form-field [config]="formConfig.smallChin"></form-field>

    <hr/>

    <form-field [config]="formConfig.smoking"></form-field>
    <form-field [config]="formConfig.useOfSleepingPills"></form-field>
    <form-field [config]="formConfig.alcoholConsumption"></form-field>

    <hr/>

    <form-header [label]="formConfig.frequencyHeaderLabel"
                 [columnLabels]="formConfig.frequencyHeaderColumnLabels"></form-header>

    <form-field [config]="formConfig.morningHeadaches"></form-field>
    <form-field [config]="formConfig.breathingLoss"></form-field>
    <form-field [config]="formConfig.fluttering"></form-field>
    <form-field [config]="formConfig.snoring"></form-field>
    <form-field [config]="formConfig.restlessSleep"></form-field>
    <form-field [config]="formConfig.daytimeSleepiness"></form-field>
    <form-field [config]="formConfig.dryMouthInMorning"></form-field>
    <form-field [config]="formConfig.needToUrinate"></form-field>

    <hr/>

    <form-header [label]="formConfig.probabilityHeaderLabel"
                 [columnLabels]="formConfig.probabilityHeaderColumnLabels"></form-header>

    <form-field [config]="formConfig.sittingReading"></form-field>
    <form-field [config]="formConfig.watchingTelevision"></form-field>
    <form-field [config]="formConfig.sittingPassive"></form-field>
    <form-field [config]="formConfig.travelInCarForAnHour"></form-field>
    <form-field [config]="formConfig.lyingDownInAfternoon"></form-field>
    <form-field [config]="formConfig.sittingTalkingToSomeone"></form-field>
    <form-field [config]="formConfig.sitInPeaceAfterNonAlcoholicLunch"></form-field>
    <form-field [config]="formConfig.sitInCarAfterItHasStoppedForFewMinutes"></form-field>

    <hr/>

    <form-field [config]="formConfig.currentMedication"></form-field>

  </div>
  <div
    data-testid="post-measurement-form-SLEEP"
    *ngIf="showPostMeasurementFields"
  >

    <ng-container *ngIf="showPreMeasurementFields">
      <hr/>
      <form-header [label]="formConfig.eventsDuringMeasurementLabel"></form-header>
    </ng-container>

    <form-field [config]="formConfig.sleepQuality"></form-field>
    <form-field [config]="formConfig.fallingAsleepDuration"></form-field>
    <form-field [config]="formConfig.sleepHours"></form-field>
    <form-field [config]="formConfig.minutesAwakeAfterMeasurementStart"></form-field>
    <form-field [config]="formConfig.awakeningsDuringMeasurement"></form-field>

    <hr/>

    <form-field [config]="formConfig.sleepDiary"></form-field>
    <form-field [config]="formConfig.patientFeedback"></form-field>

    <hr/>

    <form-field [config]="formConfig.nurseComments"></form-field>

  </div>
</div>
