<div>

  <form-field-wrapper
    data-testId="findings-generator"
    [hidden]="isManualMode"
    [config]="config"
  >
    <button
      data-testId="findings-generator-download-external-analysis-button"
      class="btn btn-primary btn-sm"
      type="button"
      (click)="getExternalFindings()"
    >
      {{ 'generator_button_findings_getExternalAnalysis' | localize }}
    </button>
    <div
      *ngIf="areExternalFindingsMissingFields"
      class="d-flex mt-1 text-secondary small"
    >
      <i class="fa fa-exclamation-triangle p-1" aria-hidden="true"></i>
      {{ localizationKey("externalFindingsMissingFieldsWarningLabel") | localize }}
    </div>

    <app-value-generator
      label="generator_label_findings_prevailingRhythm"
      data-testId="findings-generator-prevailing-rhythm"
      [value]="valueFindings.prevailingRhythm.value"
      [text]="valueFindings.prevailingRhythm.text"
      (onGenerate)="findingGenerated('prevailingRhythm', $event)"
    ></app-value-generator>
    <app-value-generator
      label="generator_label_findings_averageHr"
      data-testId="findings-generator-average-heart-rate"
      [value]="valueFindings.averageHeartRate.value"
      [text]="valueFindings.averageHeartRate.text"
      (onGenerate)="findingGenerated('averageHeartRate', $event)"
    ></app-value-generator>
    <app-value-generator
      label="generator_label_findings_minimumHr"
      data-testId="findings-generator-minimum-heart-rate"
      [value]="valueFindings.minimumHeartRate.value"
      [text]="valueFindings.minimumHeartRate.text"
      (onGenerate)="findingGenerated('minimumHeartRate', $event)"
    ></app-value-generator>
    <app-value-generator
      label="generator_label_findings_maximumHr"
      data-testId="findings-generator-maximum-heart-rate"
      [value]="valueFindings.maximumHeartRate.value"
      [text]="valueFindings.maximumHeartRate.text"
      (onGenerate)="findingGenerated('maximumHeartRate', $event)"
    ></app-value-generator>
    <app-value-generator
      label="generator_label_findings_pausesOver2_5s"
      data-testId="findings-generator-pauses-over-2_5-s-heart-rate"
      [value]="valueFindings.pausesOver2_5s.value"
      [text]="valueFindings.pausesOver2_5s.text"
      (onGenerate)="findingGenerated('pausesOver2_5s', $event)"
    ></app-value-generator>
    <app-value-generator
      label="generator_label_findings_ventricularBeats"
      data-testId="findings-generator-ventricular-beats"
      [value]="valueFindings.ventricularBeats.value"
      [text]="valueFindings.ventricularBeats.text"
      (onGenerate)="findingGenerated('ventricularBeats', $event)"
    ></app-value-generator>
    <app-value-generator
      label="generator_label_findings_ventricularTachycardias"
      data-testId="findings-generator-ventricular-tachycardias"
      [value]="valueFindings.ventricularTachycardias.value"
      [text]="valueFindings.ventricularTachycardias.text"
      (onGenerate)="findingGenerated('ventricularTachycardias', $event)"
    ></app-value-generator>
    <app-value-generator
      label="generator_label_findings_commentVentricular"
      data-testId="findings-generator-comment-ventricular"
      [text]="valueFindings.commentVentricular.text"
      (onGenerate)="findingGenerated('commentVentricular', $event)"
    ></app-value-generator>
    <app-value-generator
      label="generator_label_findings_atrialBeats"
      data-testId="findings-generator-atrial-beats"
      [value]="valueFindings.atrialBeats.value"
      [text]="valueFindings.atrialBeats.text"
      (onGenerate)="findingGenerated('atrialBeats', $event)"
    ></app-value-generator>
    <app-value-generator
      label="generator_label_findings_atrialTachycardias"
      data-testId="findings-generator-atrial-tachycardias"
      [value]="valueFindings.atrialTachycardias.value"
      [text]="valueFindings.atrialTachycardias.text"
      (onGenerate)="findingGenerated('atrialTachycardias', $event)"
    ></app-value-generator>
    <app-value-generator
      label="generator_label_findings_commentAtrial"
      data-testId="findings-generator-comment-atrial"
      [text]="valueFindings.commentAtrial.text"
      (onGenerate)="findingGenerated('commentAtrial', $event)"
    ></app-value-generator>
    <app-selection-generator
      label="generator_label_findings_avConduction"
      [options]="selectionFindings!.avConduction.options"
      [optionsInRow]="true"
      [name]="'av-conduction'"
      data-testId="findings-generator-av-conduction"
      (onGenerate)="selectionFindingGenerated('avConduction', $event)"
    ></app-selection-generator>
    <app-selection-generator
      label="generator_label_findings_deltaWave"
      [options]="selectionFindings!.deltaWave.options"
      [optionsInRow]="true"
      [name]="'delta-wave'"
      data-testId="findings-generator-delta-wave"
      (onGenerate)="selectionFindingGenerated('deltaWave', $event)"
    ></app-selection-generator>
    <app-selection-generator
      label="generator_label_findings_qrsWidth"
      [options]="selectionFindings!.qrsWidth.options"
      [name]="'qrs-width'"
      [optionsInRow]="true"
      data-testId="findings-generator-qrs-width"
      (onGenerate)="selectionFindingGenerated('qrsWidth', $event)"
    ></app-selection-generator>
    <app-selection-generator
      label="generator_label_findings_qtTime"
      [options]="selectionFindings!.qtTime.options"
      [name]="'qt-time'"
      [optionsInRow]="true"
      data-testId="findings-generator-qt-time"
      (onGenerate)="selectionFindingGenerated('qtTime', $event)"
    ></app-selection-generator>
    <app-selection-generator
      label="generator_label_findings_stSegment"
      [options]="selectionFindings!.stSegment.options"
      [name]="'st-segment'"
      [optionsInRow]="true"
      data-testId="findings-generator-st-segment"
      (onGenerate)="selectionFindingGenerated('stSegment', $event)"
    ></app-selection-generator>
    <app-value-generator
      label="generator_label_findings_symptomDiaryComment"
      data-testId="findings-generator-symptom-diary-comment"
      [text]="valueFindings.symptomDiaryComment.text"
      (onGenerate)="findingGenerated('symptomDiaryComment', $event)"
    ></app-value-generator>
    <div class="border-top border-gray pt-2 mt-3">
      <span
        data-testId="findings-generator-preview"
        class=""
      >{{ preview }}</span>
    </div>
  </form-field-wrapper>

  <ng-content
    *ngIf="isManualMode"
  ></ng-content>

  <div class="d-flex flex-row-reverse">
    <action-button
      *ngIf="!isManualMode"
      [testId]="'findings-generator-done'"
      buttonClass="btn btn-sm btn-outline-success me-1 mb-1 align-self-end"
      buttonLabel="analysisPatientStatusCommentsGeneratorDoneButton"
      [actionInProgress]="isWaitingForOutdatedConfirm"
      [buttonDisabled]="false"
      buttonType="button"
      actionInProgressLabel="analysisPatientStatusCommentsGeneratorDoneButton"
      (buttonClick)="switchToManualMode()"
    >
    </action-button>

    <button
      data-testId="findings-generator-regenerate"
      (click)="isWaitingForRegenerateConfirmation = true"
      *ngIf="isManualMode && !isWaitingForRegenerateConfirmation"
      type="button"
      class="btn btn-sm btn-outline-secondary me-1"
    >
      {{ localizationKey("analysisPatientStatusCommentsRegenerateButton") | localize }}<i
      class="fa fa-chevron-right mx-1"></i>
    </button>

    <button
      (click)="isWaitingForRegenerateConfirmation = false"
      *ngIf="isWaitingForRegenerateConfirmation"
      type="button"
      class="btn btn-sm btn-outline-secondary me-1"
    >
      {{ localizationKey("analysisPatientStatusCommentsCancelRegenerateButton") | localize }}
    </button>
    <button
      (click)="isWaitingForRegenerateConfirmation = false; regenerate()"
      *ngIf="isWaitingForRegenerateConfirmation"
      type="button"
      class="btn btn-sm btn-outline-primary me-1"
      data-testId="findings-generator-regenerate-confirm"
    >
      {{ localizationKey("analysisPatientStatusCommentsConfirmRegenerateButton") | localize }}
    </button>
  </div>
  <div
    *ngIf="isWaitingForRegenerateConfirmation"
    class="d-flex flex-row-reverse mt-1 text-secondary small"
  >
    {{ localizationKey("analysisRegenerationWarningLabel") | localize }}
    <i class="fa fa-exclamation-triangle p-1" aria-hidden="true"></i>
  </div>
  <div
    *ngIf="areValuesOutdated"
    class="d-flex flex-row-reverse mt-1 text-danger"
  >
    {{ localizationKey("analysisDataOutdatedErrorLabel") | localize }}
    <i class="fa fa-exclamation-triangle p-1" aria-hidden="true"></i>
  </div>
</div>
