import {ApiClient} from "../../../tools/api-client";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ChainDetails, ChainSettings} from "../chains-model";

@Injectable()
export class ChainDetailsService {

  constructor(private readonly apiClient: ApiClient) {
  }

  getDetails(chainId: number): Observable<ChainDetails> {
    return this.apiClient.get<ChainDetails>(`api/chain/${chainId}`);
  }

  saveSettings(chainId: number, chainSettings: ChainSettings): Observable<void> {
    return this.apiClient.put(`api/chain/${chainId}/settings`, chainSettings);
  }
}
