import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FoundMeasurement} from "../found-measurement";
import {ApiClient} from "../../../tools/api-client";
import {recursiveNullToUndefined} from "../../../tools/recursive-null-to-undefined";
import {map} from "rxjs/operators";

@Injectable()
export class RepeatMeasurementService {

  constructor(private readonly apiClient: ApiClient) {
  }

  repeat(measurementId: number): Observable<FoundMeasurement> {
    return this.apiClient
      .put<FoundMeasurement>(`api/measurement/${measurementId}/repeat`)
      .pipe(map(recursiveNullToUndefined));
  }

}
