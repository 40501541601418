export type ValidationFunctions<T> = Map<keyof T, (value: any, values: T) => boolean>;

export class FormValidator<T> {

  private validationMap: Map<keyof T, boolean> = new Map<keyof T, boolean>;

  constructor(
    private readonly formModel: T,
    private readonly validationFunctions: ValidationFunctions<T>
  ) {

  }

  updatePropertyValidation(property: keyof T) {
    if (this.validationFunctions.has(property)) {
      this.validationMap.set(property, this.validationFunctions.get(property)!(this.formModel[property], this.formModel));
    }
  }

  isPropertyValid(property: keyof T) {
    return this.validationMap.get(property);
  }

  updateAllValidations() {
    this.validationFunctions.forEach((_, key) => this.updatePropertyValidation(key));
  }

  isFormValid(): boolean {
    return Array.from(this.validationFunctions.keys()).every((key) => this.validationMap.get(key) !== false);
  }

  resetValidationStatuses() {
    this.validationMap = new Map<keyof T, boolean>();
  }

}
