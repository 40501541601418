<div class="row justify-content-center">
  <div class="col-6">
    <div class="row">
      <h5>{{ localizationKey('hospitalNurseSettingsTitle') | localize }}</h5>
    </div>

    <label class="w-100 my-2">
      <input
        type="checkbox"
        data-testid="nurse-2fa"
        [(ngModel)]="nurseSettingsSectionStateService.nurseSettings!.nurse2Fa"
      />
      {{ localizationKey('hospitalNurseSettings2FaCheckbox') | localize }}
    </label>

    <div class="my-2">
      <div class="row">
        <h6>{{ localizationKey('reportDeliveryNotificationSettingsTitle') | localize }}</h6>
      </div>

      <div class="row my-1">
        <div class="col-6">
          <input
            id="notifications-none"
            data-testId="report-delivery-notifications-none"
            type="radio"
            [value]="'NONE'"
            [(ngModel)]="nurseSettingsSectionStateService.nurseSettings!.reportDeliveryNotification">
          <label for="notifications-none" class="ms-1">
            {{ localizationKey('noReportDeliveryNotifications') | localize }}
          </label>
        </div>
      </div>
      <div class="row my-1">
        <div class="col-6">
          <input
            id="notifications-initiating"
            data-testId="report-delivery-notifications-initiating-nurse"
            type="radio"
            [value]="'INITIATING_NURSE'"
            [(ngModel)]="nurseSettingsSectionStateService.nurseSettings!.reportDeliveryNotification">
          <label for="notifications-initiating" class="ms-1">
            {{ localizationKey('initiatingNurseReportDeliveryNotifications') | localize }}
          </label>
        </div>
      </div>
      <div class="row my-1">
        <div class="col-6">
          <input
            id="notifications-all-nurses"
            data-testId="report-delivery-notifications-all-hospital-nurses"
            type="radio"
            [value]="'ALL_HOSPITAL_NURSES'"
            [(ngModel)]="nurseSettingsSectionStateService.nurseSettings!.reportDeliveryNotification">
          <label for="notifications-all-nurses" class="ms-1">
            {{ localizationKey('allNursesReportDeliveryNotifications') | localize }}
          </label>
        </div>
      </div>

    </div>

    <div class="mt-3">
      <button class="btn btn-sm btn-primary" data-testid="submit-nurse-settings"
              (click)="submitNurseSettings()">
        {{ localizationKey('hospitalNurseSettingsUpdateButton') | localize }}
      </button>
    </div>
  </div>
</div>
