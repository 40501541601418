<div>
  <div *ngIf="!chunkedFileUploader.file">
    <input
      type="file"
      name="measurementFile"
      data-testid="file-upload-input"
      #fileInput
      [accept]="getAllowedExtension()"
      (change)="handleFileSelection($event); fileInput.value = ''"
    />
    <div *ngIf="isFileValid === false" class="form-field-invalid-feedback">
      {{ measurementFileConfig.invalidFileLabel | localize }}
    </div>
  </div>

  <div *ngIf="!!chunkedFileUploader.file">
    <div>{{ chunkedFileUploader.file.name }}</div>

    <div class="progress">
      <div [ngClass]="{'progress-bar': true, 'bg-success': progressBarShouldBeGreen()}" [style.width]="uploadPercentageStyle()">
        <span data-testid="file-uploaded-percentage">{{ uploadPercentageText() }}</span>
      </div>
    </div>

    <div class="btn-group-sm mt-1">
      <button *ngIf="errorMessage" class="btn btn-outline-primary me-2" (click)="retryUpload()" type="button">
        {{ "uploadFileButtonRetry" | localize }}
      </button>

      <button class="btn btn-sm btn-outline-secondary float-right" (click)="cancelUpload()" type="button">
        {{ (chunkedFileUploader.isComplete ? "uploadFileButtonDelete" : "uploadFileButtonCancel") | localize }}
      </button>
    </div>
  </div>

  <div *ngIf="errorMessage" class="alert alert-danger mt-2">
    {{ errorMessage | localize }}
  </div>
</div>
