<div class="py-2">
  <div [ngClass]="{ 'd-flex': optionsInRow }">
    <div [ngClass]="{ 'col-6': optionsInRow, 'd-flex justify-content-between': optionsInRow }">
      <span [ngClass]="{ 'generator-title':true, 'col-6': optionsInRow, 'me-3': true }">{{ label | localize }}</span>

      <label
        data-testId="generation-option"
        *ngFor="let option of options"
        class="me-2"
      >
        <input
          type="radio"
          name="{{ name }}"
          (click)="radioButtonClicked(option)"
          [value]="option"
          [ngModel]="selectedOption"
          (ngModelChange)="selectOption(option)"
        />
        {{ option.optionLabel | localize }}
      </label>
    </div>
    <app-generator-text-field
      [ngClass]="{ 'col-6': optionsInRow, 'col-12': !optionsInRow}"
      [testId]="testId"
      [text]="generatedText"
      (onTextChange)="onGeneratedTextEdited($event)"
    >
    </app-generator-text-field>
  </div>
</div>
