<action-button
        [testId]="testId"
        buttonType="button"
        buttonClass="btn btn-sm btn-primary me-4 mb-1"
        [buttonLabel]="'measurementsGridButtonLabelReassignDoctor'"
        [actionInProgress]="this.item.isReassignInProgress"
        (buttonClick)="onReassignClick()"></action-button>

<ng-template #templateConfirmReassignDoctor>
    <div class="modal-body text-center">
        <p>
            {{ "confirmReassignDoctor" | localize }}
        </p>
        <button type="button" class="btn btn-default" (click)="reassign()"
                data-testid="confirm-reassign-doctor-button">
            {{ "modalResponseYes" | localize }}
        </button>
        <button type="button" class="btn btn-primary" (click)="modalRefReassignDoctorConfirm?.hide()">
            {{ "modalResponseNo" | localize }}
        </button>
    </div>
</ng-template>
