<div>

  <form-field-wrapper
    data-testId="patient-status-comments-generator"
    [hidden]="isManualMode"
    [config]="config"
  >
    <app-selection-generator data-testId="reason-of-study-comment-generator"
                        [options]="patientStatusComments!.reasonForStudy.options"
                        (onGenerate)="commentGenerated('reasonForStudy', $event)"
                        name="reason-of-study-comment-generator"
                        [label]="localizationKey('generator_label_patientStatusComments_reasonForStudy')"
    ></app-selection-generator>
    <app-selection-generator data-testId="rhythm-affecting-medication-comments-generator"
                        [options]="patientStatusComments!.rhythmAffectingMedication.options"
                        (onGenerate)="commentGenerated('rhythmAffectingMedication', $event)"
                        name="rhythm-affecting-medication-comments-generator"
                        [label]="localizationKey('generator_label_patientStatusComments_rhythmAffectingMedication')"
    ></app-selection-generator>
    <app-selection-generator data-testId="symptoms-during-recording-generator"
                        [options]="patientStatusComments!.symptomsDuringRecording.options"
                        (onGenerate)="commentGenerated('symptomsDuringRecording', $event)"
                        name="symptoms-during-recording-generator"
                        [label]="localizationKey('generator_label_patientStatusComments_symptomsDuringRecording')"
    ></app-selection-generator>
    <div class="border-top border-gray pt-2 mt-3">
      <span class="" data-testId="patient-status-comments-generator-preview">{{ preview }}</span>
    </div>
  </form-field-wrapper>

  <ng-content
    *ngIf="isManualMode"
  ></ng-content>

  <div class="d-flex flex-row-reverse">
    <button
      *ngIf="!isManualMode"
      data-testId="patient-status-comments-generator-done"
      (click)="isManualMode = true"
      type="button"
      class="btn btn-sm btn-outline-success me-1 mb-1 align-self-end"
    >
      {{ localizationKey("analysisPatientStatusCommentsGeneratorDoneButton") | localize}}
    </button>
    <button
      data-testId="patient-status-comments-generator-regenerate"
      (click)="isWaitingForRegenerateConfirmation = true"
      *ngIf="isManualMode && !isWaitingForRegenerateConfirmation"
      type="button"
      class="btn btn-sm btn-outline-secondary me-1"
    >
      {{ localizationKey("analysisPatientStatusCommentsRegenerateButton") | localize }}<i
      class="fa fa-chevron-right mx-1"></i>
    </button>

    <button
      (click)="isWaitingForRegenerateConfirmation = false"
      *ngIf="isWaitingForRegenerateConfirmation"
      type="button"
      class="btn btn-sm btn-outline-secondary me-1"
    >
      {{ localizationKey("analysisPatientStatusCommentsCancelRegenerateButton") | localize }}
    </button>
    <button
      (click)="isWaitingForRegenerateConfirmation = false; regenerate()"
      *ngIf="isWaitingForRegenerateConfirmation"
      type="button"
      class="btn btn-sm btn-outline-primary me-1"
      data-testId="patient-status-comments-generator-regenerate-confirm"
    >
      {{ localizationKey("analysisPatientStatusCommentsConfirmRegenerateButton") | localize }}
    </button>
  </div>
  <div
    *ngIf="isWaitingForRegenerateConfirmation"
    class="d-flex flex-row-reverse mt-1 text-secondary small"
  >
    {{ localizationKey("analysisRegenerationWarningLabel") | localize }}
    <i class="fa fa-exclamation-triangle p-1" aria-hidden="true"></i>
  </div>
</div>
