<button
  type="button"
  data-testid="mailing-info-button"
  class="btn btn-sm btn-secondary me-2 mb-1"
  (click)="showModal()"
>
  <i class="fa fa-envelope"></i>
</button>

<ng-template #templateMailingInfo>
  <div class="modal-body text-center" data-testid="mailing-info-modal">

    <ng-container *ngIf="gettingMailingInfoFailed">
      <div>{{ "gettingMailingInfoFailureLabel" | localize }}</div>
    </ng-container>

    <ng-container *ngIf="!gettingMailingInfoFailed && mailingInfo===undefined">
      <loading-indicator></loading-indicator>
    </ng-container>

    <div
      *ngIf="mailingInfo!==undefined"
      class="m-3 text-start"
    >
      <div
        class="d-flex mb-2"
        *ngFor="let entry of [
          ['mailingInfoNameLabel', mailingInfo.name],
          ['mailingInfoStreetAddressLabel', mailingInfo.streetAddress],
          ['mailingInfoPostalCodeLabel', mailingInfo.postalCode],
          ['mailingInfoPostalAreaLabel', mailingInfo.postalArea],
          ['mailingInfoPhoneNumberLabel', mailingInfo.phoneNumber],
          ['mailingInfoEmailAddressLabel', mailingInfo.emailAddress],
        ]"
      >
        <div class="strong col-4 text-end">{{ entry[0] | localize }}</div>
        <div class="multiline ms-3">
          <span>{{ entry[1] }}</span>
        </div>
      </div>
    </div>

    <button
      data-testId="close-mailing-info-modal-button"
      class="btn btn-primary mt-3"
      (click)="closeModal()"
    >
      {{ "closeButtonLabel" | localize }}
    </button>
  </div>
</ng-template>
