<form-field-wrapper *ngIf="isVisible()" [config]="config" [ngSwitch]="config!.type"
                    [valueOverCharacterLimit]="valueOverCharacterLimit()">
  <input
    *ngSwitchCase="'text'"
    type="text"
    [id]="config!.property"
    [name]="widgetName"
    class="form-control"
    [ngModel]="config!.model[config!.property]"
    (ngModelChange)="modelChanged($event)"
    [attr.data-testid]=dataTestId
    [attr.disabled]="isReadOnly()"
  />

  <input
    *ngSwitchCase="'positiveWholeNumber'"
    [id]="config!.property"
    [name]="widgetName"
    class="form-control"
    [ngModel]="config!.model[config!.property]"
    (ngModelChange)="modelChanged($event)"
    (input)="sanitizeNumber($event)"
    [attr.data-testid]=dataTestId
    [attr.disabled]="isReadOnly()"
    inputmode="numeric"
  />

  <input
    *ngSwitchCase="'number'"
    type="number"
    [id]="config!.property"
    [name]="widgetName"
    class="form-control"
    [ngModel]="config!.model[config!.property]"
    (ngModelChange)="modelChanged($event)"
    [attr.data-testid]=dataTestId
    [attr.disabled]="isReadOnly()"
  />

  <input
    *ngSwitchCase="'file'"
    type="file"
    [id]="config!.property"
    [name]="widgetName"
    class="form-control"
    [accept]="config!.fileOptions!.accept"
    (change)="handleFileSelection($event)"
    [attr.data-testid]=dataTestId
    [attr.disabled]="isReadOnly()"
  />

  <select
    *ngSwitchCase="'dropdown'"
    [id]="config!.property"
    [name]="widgetName"
    class="form-select"
    [ngModel]="config!.model[config!.property]"
    (ngModelChange)="modelChanged($event)"
    [attr.data-testid]=dataTestId
    [attr.disabled]="config!.readOnly || undefined"
  >
    <option
      *ngFor="let option of config!.selectionOptions?.items || []"
      [ngValue]="option.value"
      [disabled]="option.disabled ? 'disabled' : undefined"
    >
      {{ option.text | localize }}
    </option>
  </select>

  <div *ngSwitchCase="'radio'" class="container pt-1">
    <div
      [ngClass]="{
        'd-flex gap-3': !responsiveLayout,
        'justify-content-between':  (!!config?.selectionOptions?.isColumnLayout && !responsiveLayout)
      }"
    >
      <div *ngFor="let option of config!.selectionOptions?.items || []" [ngClass]="{'striped-radio my-1': responsiveLayout}">
        <label class="pr-3 w-100">
          <input
            type="radio"
            [name]="widgetName"
            [value]="option.value"
            [ngModel]="config!.model[config!.property]"
            (ngModelChange)="modelChanged($event)"
            (click)="radioButtonClicked(option.value)"
            [attr.data-testid]="dataTestId ? dataTestId + '-' + option.value : undefined"
          />
          {{ (!!config?.selectionOptions?.isColumnLayout && !responsiveLayout) ? "" : (option.text | localize) }}
        </label>
      </div>
      <hr *ngIf="responsiveLayout"/>
    </div>
  </div>

  <div *ngSwitchCase="'header'" class="container">
    <div
      *ngIf="!responsiveLayout"
      class="d-flex justify-content-between"
    >
      <div *ngFor="let option of config!.selectionOptions?.items || []" class="pr-3">
        {{ option.text | localize }}
      </div>
    </div>
  </div>

  <textarea
    [autosize]="config!.autoexpand || false"
    *ngSwitchCase="'textarea'"
    [id]="config!.property"
    [name]="widgetName"
    class="form-control"
    [ngModel]="config!.model[config!.property]"
    (ngModelChange)="modelChanged($event)"
    [attr.data-testid]=dataTestId
    [attr.disabled]="isReadOnly()"
  ></textarea>

  <input
    *ngSwitchCase="'password'"
    type="password"
    [id]="config!.property"
    [name]="widgetName"
    class="form-control"
    [ngModel]="config!.model[config!.property]"
    (ngModelChange)="modelChanged($event)"
    [attr.data-testid]=dataTestId
    [attr.disabled]="isReadOnly()"
  />

  <app-date-time-picker
    *ngSwitchCase="'localdate'"
    class="col-auto form-control-plaintext"
    [dateTime]="getPropertyValueAsDateTime()"
    (dateTimeChange)="modelChanged($event)"
    [attr.data-testid]=dataTestId
    [dataTestId]=dataTestId
    [disabled]="!!isReadOnly()"
  ></app-date-time-picker>

  <div class="my-auto">
    <input
      *ngSwitchCase="'checkbox'"
      type="checkbox"
      [id]="config!.property"
      [name]="widgetName"
      class="form-check-input"
      [ngModel]="config!.model[config!.property]"
      (ngModelChange)="modelChanged($event)"
      [attr.data-testid]=dataTestId
      [attr.disabled]="isReadOnly()"
    />
  </div>

</form-field-wrapper>
