import {Component, Input, OnInit, Output} from "@angular/core";
import {FormFieldConfig} from "./form-field-config";
import {environment} from "../../../environments/environment";
import {MAX_CHARACTERS_IN_TEXT} from "../../features/utils";
import {DateTime} from "luxon";
import {DateTimeSerializationService} from "../formatting/date-time-serialization.service";
import {getFirstSelectedFile} from "./get-first-selected.file";

@Component({
  selector: "form-field",
  templateUrl: "./form-field.component.html",
  host: {class: "form-field"},
})
export class FormFieldComponent<T> implements OnInit {
  @Input() config: FormFieldConfig<T> | undefined;

  dataTestId?: string;

  widgetName = "notInitialized";

  responsiveLayout = environment.eforms;

  constructor(private readonly dateTimeSerializationService: DateTimeSerializationService) {
  }

  ngOnInit(): void {
    if (this.config === undefined) {
      throw Error("Missing config!");
    }

    this.widgetName = String(this.config.property);
    this.dataTestId = this.widgetName;

    const isTypeWithOptions: boolean = ["dropdown", "radio", "header"].includes(this.config.type);

    if (isTypeWithOptions && this.config.selectionOptions === undefined) {
      throw Error(`Missing options in config for property: ${String(this.config.property || this.config.label)}!`);
    }
  }

  modelChanged(value: any) {

    const oldValue = this.config?.model[this.config.property];

    if (this.config!.type === "positiveWholeNumber" && value !== undefined) {
      const sanitizedInput = value.replace(/[^0-9]/g, "");
      value = sanitizedInput === "" ? undefined : Number(sanitizedInput);
    }

    if (this.config!.type === "localdate" && value !== undefined) {
      value = this.dateTimeSerializationService.serializeWithoutOffset(value as DateTime);
    }

    if (oldValue !== value) {
      this.config?.propertyChangeListener?.handlePropertyChange(this.config.property, value);
    }
  }

  getPropertyValueAsDateTime(): DateTime | undefined {
    const value = this.config!.model[this.config!.property];

    if (value === undefined) {
      return undefined;
    }

    if (typeof value == "string") {
      return DateTime.fromISO(value);
    }

    throw Error("Invalid datetime picker type.");
  }

  getPropertyValueAsString(): string | undefined {
    const value = this.config!.model[this.config!.property];

    if (value === undefined) {
      return undefined;
    }

    return String(value);
  }

  isReadOnly(): boolean | undefined {
    return this.config!.readOnly || undefined;
  }

  isVisible(): boolean {

    if (!this.config) {
      return false;
    }

    return !this.config.filledByNurseOnly || this.config.filledByNurseOnly !== environment.eforms;
  }


  @Output()
  valueOverCharacterLimit(): boolean {
    const value = this.getPropertyValueAsString();

    return value === undefined ? false : value.length > MAX_CHARACTERS_IN_TEXT;

  }

  radioButtonClicked(value: any) {
    if (this.config!.model[this.config!.property] === value) {
      this.modelChanged(undefined);
    }
  }

  handleFileSelection($event: Event) {
    this.modelChanged(getFirstSelectedFile($event));
  }

  sanitizeNumber(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, "");
  }
}

export class SelectionOptions<T> {
  static with<T>(...options: SelectionOption<T>[]): SelectionOptions<T> {
    return new SelectionOptions<T>(options, false);
  }

  static inColumnsWith<T>(...options: SelectionOption<T>[]): SelectionOptions<T> {
    return new SelectionOptions<T>(options, true);
  }

  private constructor(readonly items: SelectionOption<T>[], readonly isColumnLayout: boolean) {
  }
}

export interface SelectionOption<T> {
  value: T;
  text?: String;
  disabled?: boolean;
}

export interface FileOptions {
  accept: string;
}
