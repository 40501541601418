<div
  data-testId="holter-type-editor-modal"
>
  <button
    data-testId="close-holter-type-editor-button"
    class="btn-close close pull-right"
    (click)="onClose()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
  <h3>{{"setHolterTypeButton" | localize}}</h3>
  <div class="p-4">
    <select
      data-testId="holter-type-selector"
      class="form-select col-sm-auto"
      [(ngModel)]="selectedHolterDurationDays"
    >
      <option
        *ngFor="let holterType of holterTypes"
        [value]="holterType.durationDays"
        [selected]="holterType.durationDays===selectedHolterDurationDays"
      >{{ holterType.localisationKey | localize }}
      </option>
    </select>
  </div>
  <button
    data-testId="set-holter-type-button"
    class="btn btn-primary float-end"
    (click)="onSubmit()"
  >{{"setHolterTypeButton" | localize}}
  </button>
</div>
