<button
  *ngIf="shouldShowCommentsButton()"
  type="button"
  [ngClass]="'btn btn-sm me-4 mb-1 ' + getCommentsButtonType()"
  data-testid="show-comments-button"
  (click)="showComments()"
  [title]="getCommentButtonLabel('measurementsGridButtonLabelShowComments' | localize, item.foundMeasurement.commentCount)"
>
  <i class="fa fa-comment me-1"></i>
  <b>{{ String(item.foundMeasurement.commentCount || "0") }}</b>
</button>

<ng-template #templateComments>
  <app-comments
    (commentsCountChanged)="commentsCountChanged($event)"
    [measurementId]="item.foundMeasurement.id"
    [code]="item.foundMeasurement.code"
    [hospitalName]="item.foundMeasurement.hospitalName"
    (close)="modalRef!.hide()"
  >
  </app-comments>
</ng-template>
