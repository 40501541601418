<button
  *ngIf="canUploadReport() && !item.showReportUpload"
  type="button"
  class="btn btn-sm btn-primary me-1 mb-1"
  (click)="onUploadReportClick()"
>
  {{ "measurementsGridButtonLabelUploadReport" | localize }}
</button>

<report-upload
  *ngIf="item.showReportUpload"
  [item]="item.foundMeasurement"
  (fileUploaded)="onReportFileUploaded($event)"
  (fileUploadCanceled)="onReportFileUploadCancelled()">
</report-upload>


<app-download-report
  *ngIf="canDownloadReport()"
  [item]="item"
></app-download-report>


<app-navigate-to-print-form
  [user]="user"
  [item]="item">
</app-navigate-to-print-form>

<app-navigate-to-order-form
  [user]="user"
  [item]="item">
</app-navigate-to-order-form>

<app-upload-initiated-button
  [foundMeasurement]="item.foundMeasurement">
</app-upload-initiated-button>

<action-button
  *ngIf="shouldShowAssignTechnicianButton()"
  buttonType="button"
  buttonClass="btn btn-sm btn-primary me-1 mb-1"
  [buttonLabel]="'measurementsGridAssignTechnicianButton'"
  [actionInProgressLabel]="'measurementsGridAssignTechnicianInProgress'"
  [actionInProgress]="item.isAssignTechnicianInProgress"
  [testId]="'assign-technician-button'"
  (buttonClick)="onAssignTechnicianClick()"></action-button>

<button *ngIf="isAssignable()" type="button"
        data-testId="assign-doctor-button"
        [classList]="getAssignButtonColorClass() + ' btn btn-sm me-1 mb-1'"
        (click)="onAssignClick()">
  {{ getAssignButtonLabel() | localize }}
</button>

<app-pre-confirm-action-button
  *ngIf="canRepeatMeasurement() && feature379Released"
  buttonType="button"
  buttonClass="btn btn-sm btn-danger me-1 mb-1"
  [toolTip]="'measurementsGridRepeatMeasurementButtonLabel'"
  [testId]="'repeat-measurement-button'"
  (buttonClick)="repeatMeasurement()"
  [iconClass]="'fa fa-solid fa-rotate-right'"
  [buttonState]="repeatMeasurementState"
  [confirmationLabel]="'measurementsGridRepeatMeasurementButtonConfirmationLabel'"
></app-pre-confirm-action-button>

<button *ngIf="shouldShowCopyButton()" type="button" class="btn btn-sm btn-secondary me-1 mb-1"
        (click)="onCopyClick()">
  {{ "measurementsGridButtonLabelCopy" | localize }}
</button>

<button
  *ngIf="shouldShowOpenAnalysisButton()"
  type="button"
  class="btn btn-sm btn-secondary me-4 mb-1"
  data-testid="open-analysis-button"
  (click)="onOpenAnalysisClick()">
  {{ "measurementsGridButtonLabelOpenAnalysis" | localize }}
</button>


<app-pre-confirm-action-button
  *ngIf="shouldShowCloseAnalysisButton()"
  [buttonLabel]="'measurementsGridButtonLabelCloseAnalysis' | localize"
  [actionInProgressLabel]="'measurementsGridButtonLabelCloseAnalysisInProgress' | localize"
  [buttonType]="'button'"
  [confirmationLabel]="'measurementsGridCloseAnalysisButtonConfirmationLabel' | localize"
  (buttonClick)="onCloseAnalysisClick()"
  [buttonState]="closeAnalysisActionState"
  [buttonClass]="'btn btn-primary btn-sm me-2 mb-1'"
  [testId]="'close-analysis-button'">
</app-pre-confirm-action-button>

<action-button
  *ngIf="shouldShowDeleteButton() && user.role==='DATA_OFFICER'"
  buttonType="button"
  buttonClass="btn btn-sm btn-primary me-2 mb-1"
  [buttonLabel]="'measurementsGridButtonLabelDeleteMeasurement' | localize"
  [actionInProgressLabel]="'measurementsGridButtonLabelDeleteMeasurementInProgress' | localize"
  [actionInProgress]="item.isDeleteMeasurementInProgress"
  [testId]="'delete-measurement-button'"
  (buttonClick)="onDeleteMeasurementClick()"></action-button>

<action-button
  *ngIf="shouldShowDeleteButton() && user.role==='NURSE'"
  buttonType="button"
  buttonClass="btn btn-sm btn-danger ms-2 me-2 mb-1"
  iconClass="fa fa-remove"
  toolTip="measurementsGridButtonLabelDeleteMeasurementTooltip"
  [actionInProgress]="item.isDeleteMeasurementInProgress"
  [testId]="'delete-measurement-button'"
  (buttonClick)="onDeleteMeasurementClick()"></action-button>

<app-return-study
  *ngIf="shouldShowReturnStudy()"
  [measurementId]="item.foundMeasurement.id"
  (returnedStudy)="onReturnStudy($event)"></app-return-study>

<action-button
  *ngIf="canRejectStudy()"
  buttonType="button"
  buttonClass="btn btn-sm btn-danger me-4 mb-1"
  [testId]="'reject-measurement-button'"
  [buttonLabel]="'measurementsGridButtonLabelRejectStudy'"
  [actionInProgressLabel]="'measurementsGridButtonLabelRejectStudyInProgress'"
  [actionInProgress]="item.isRejectStudyInProgress"
  (buttonClick)="onRejectStudyClick()"></action-button>

<action-button
  *ngIf="shouldShowRestoreMeasurementButton()"
  buttonType="button"
  buttonClass="btn btn-sm btn-secondary me-4 mb-1"
  [testId]="'restore-measurement-button'"
  [buttonLabel]="'measurementsGridButtonLabelRestoreMeasurement'"
  [actionInProgressLabel]="'measurementsGridButtonLabelRestoreMeasurementInProgress'"
  [actionInProgress]="item.isRestoreMeasurementInProgress"
  (buttonClick)="restoreMeasurement()"></action-button>

<app-reassign-doctor-button
  *ngIf="shouldShowReassignButton()"
  [item]="item"
  testId="reassign-doctor-button"
  (measurementChanged)="measurementChanged.emit()"
></app-reassign-doctor-button>

<button
  *ngIf="canMailDevice()"
  type="button"
  data-testid="mail-device-button"
  (click)="mailDevice()"
  class="btn btn-sm btn-primary me-4 mb-1"
>{{ 'measurementsGridButtonLabelMailDevice' | localize }}
</button>

<app-mailing-info
  *ngIf="shouldShowMailingInfo()"
  [measurementId]="item.foundMeasurement.id"
></app-mailing-info>

<app-comments-button
  [item]="item"
  [user]="user"
 ></app-comments-button>

<ng-template #templateConfirmReject>
  <div class="modal-body text-center">
    <p>
      {{ "modalPromptAreYouSure" | localize }}
    </p>
    <button type="button" class="btn btn-default" (click)="rejectStudy()">
      {{ "modalResponseYes" | localize }}
    </button>
    <button type="button" class="btn btn-primary" (click)="modalRefRejectConfirm?.hide()">
      {{ "modalResponseNo" | localize }}
    </button>
  </div>
</ng-template>

<ng-template #templateConfirmDeleteMeasurement>
  <div class="modal-body text-center">
    <p>
      {{ generateDeleteMeasurementConfirmMessage() }}
    </p>
    <button type="button" class="btn btn-default" (click)="deleteMeasurement()"
            data-testid="delete-measurement-dialog-confirm-button">
      {{ "modalResponseYes" | localize }}
    </button>
    <button type="button" class="btn btn-primary" (click)="modalRefDeleteMeasurementConfirm?.hide()">
      {{ "modalResponseNo" | localize }}
    </button>
  </div>
</ng-template>

