import {Component, EventEmitter, Output} from "@angular/core";
import {Observable} from "rxjs";
import {emptyUserCredentialsFormModel, UserCredentialsFormModel} from "../login-form-model";
import {PropertyChangeListener} from "../../../tools/form/form-field-config";
import {LoginService} from "../login.service";
import {LoginValidator} from "../login-validator";
import {UserCredentialsConfig} from "./user-credentials-config";
import {ErrorResponse} from "../../../tools/error-response";
import {AuthenticatedUser} from "../../authentication/authenticated-user";
import {AuthenticationComponent} from "../authentication-component";
import {ReleaseToggleState} from "../../../tools/release-toggles/release-toggle-state";
import {RouteNavigator} from "../../../tools/navigation/route-navigator.service";
import {NavigationRoute} from "../../../tools/navigation/navigation-route";

@Component({
  selector: "app-user-login",
  templateUrl: "./user-credentials.component.html"
})
export class UserCredentialsComponent extends AuthenticationComponent implements PropertyChangeListener<UserCredentialsFormModel> {

  constructor(
    private readonly loginValidator: LoginValidator,
    private readonly loginService: LoginService,
    private readonly routeNavigator: RouteNavigator
  ) {
    super();
  }

  formModel: UserCredentialsFormModel = emptyUserCredentialsFormModel();

  readonly formConfig = new UserCredentialsConfig(
    this.formModel,
    this,
    (property) => this.validationStatuses.get(property));

  @Output() otpRequiredChange = new EventEmitter<UserCredentialsFormModel>();
  @Output() userAuthenticated = new EventEmitter<AuthenticatedUser>();
  @Output() notifyError = new EventEmitter<ErrorResponse>();

  feature359Released = ReleaseToggleState.getInstance().isReleased("USER_RESETS_PASSWORD_359");

  onLoginClick() {
    if (!this.isValid()) {
      return;
    }

    this.clearNotificationsAndShowProgress();

    this.doVerification((authenticatedUser: AuthenticatedUser) => {
      if (authenticatedUser.otpRequired) {
        this.otpRequiredChange.emit(this.formModel);
      } else {
        this.userAuthenticated.emit(authenticatedUser);
      }
    },
    (errorResponse: ErrorResponse) => {
      this.notifyError.emit(errorResponse);
    });
  }

  handlePropertyChange(property: keyof UserCredentialsFormModel, newValue: any): void {
    this.formModel[property] = newValue;
    this.validate(property);
  }

  validate(propertyToValidate?: keyof UserCredentialsFormModel) {
    const validationResult = this.loginValidator.validateUserCredentials(this.formModel);
    this.validateProperties(validationResult, propertyToValidate);
  }

  authenticate(): Observable<AuthenticatedUser> {
    return this.loginService.login({
      username: this.formModel.username,
      password: this.formModel.password.trim()
    });
  }

  forgotPassword() {
    this.routeNavigator.navigateTo(NavigationRoute.FORGOT_PASSWORD);
  }
}
