import {
  isExisting,
  isRequiredTextOfMaxLength,
  isValidMeasurementCode,
  isValidSsn
} from "../validations";
import {MeasurementType} from "../measurement-models";
import {InitiateMeasurementFormModel} from "./initiate-measurement-data";
import {MAX_CHARACTERS_IN_TEXT} from "../utils";
import {FormValidator, ValidationFunctions} from "../../tools/form/form-validator";

export function createInitiateMeasurementValidationFunctionMap(): ValidationFunctions<InitiateMeasurementFormModel> {
  return new Map<keyof InitiateMeasurementFormModel, (value: any, values: InitiateMeasurementFormModel) => boolean>()

    .set("code", (value: string, values: InitiateMeasurementFormModel) => {
      return validateCode(values.code, values.ssn);
    })
    .set("reasonForStudy", (value: string) => isRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, value))
    .set("type", (value: MeasurementType) => isExisting(value))
    .set("ssn", (value: string, values: InitiateMeasurementFormModel) => {
      return validateSsn(values.code, values.ssn);
    });
}

export function validateCode(code?: string, ssn?: string): boolean {
  if (isExisting(code) && isValidMeasurementCode(code)) {
    return true;
  }
  if (!isExisting(code)) {
    return isExisting(ssn) && isValidSsn(ssn);
  }
  return false;
}

export function validateSsn(code?: string, ssn?: string): boolean {
  if (isExisting(ssn) && isValidSsn(ssn)) {
    return true;
  }
  if (!isExisting(ssn)) {
    return isExisting(code) && isValidMeasurementCode(code);
  }
  return false;
}

export class InitiateMeasurementValidator extends FormValidator<InitiateMeasurementFormModel> {

  constructor(hospitalInformation: InitiateMeasurementFormModel) {
    super(hospitalInformation, createInitiateMeasurementValidationFunctionMap());
  }

}

