<div data-testId="mail-device-page">
  <form class="card-body">
    <div class="my-3">
      <button
        data-testId="print-mailing-info-button"
        class="btn btn-secondary"
        (click)="printMailingInfo()"
      >
        {{ "mailingConfirmationPrintMailingInfoButtonLabel" | localize }}
      </button>

      <action-button
        class="ms-2"
        [actionInProgress]="printingInProgress"
        buttonLabel="printHomePatientDataFormButtonLabel"
        actionInProgressLabel="printHomePatientDataFormButtonInProgressLabel"
        (buttonClick)="printHomePatientDataForm()"
        [testId]="'print-home-patient-data-form-button'"
      ></action-button>
    </div>

    <form-field [config]="formConfig.deviceSerialNumber"></form-field>

    <p>
      <action-button
        [actionInProgress]="confirmingInProgress"
        buttonLabel="mailingConfirmationButtonLabel"
        actionInProgressLabel="mailingConfirmationButtonInProgressLabel"
        (buttonClick)="confirmDeviceMailing()"
        [testId]="'mailing-confirmation-button'"
        [buttonDisabled]="!canConfirmMailDevice()"
      >
      </action-button>
    </p>
  </form>

</div>
