<div
  *ngIf="!deviceOrdered"
  data-testid="eform-mailing-info"
>
  <hr/>

  <form-header [label]="formConfig.mailingInfoTitle"></form-header>
  <div class="col pb-3">
    <div class="col">
      {{ "mailingDescription" | localize }}
    </div>
  </div>

  <form-field [config]="formConfig.name"></form-field>
  <form-field [config]="formConfig.streetAddress"></form-field>
  <form-field [config]="formConfig.postalCode"></form-field>
  <form-field [config]="formConfig.postalArea"></form-field>
  <form-field [config]="formConfig.phoneNumber"></form-field>
  <form-field [config]="formConfig.emailAddress"></form-field>

  <div class="col">
    <label class="col">
      <input
        type="checkbox"
        id="permission"
        [(ngModel)]="model.permission"
        (change)="permissionChanged()"
        data-testid="permission"/>
      {{ "mailingInfoFormPermissionLabel" | localize }}
    </label>
  </div>

  <div class="mt-3">

    <app-pre-confirm-action-button
      [buttonLabel]="'mailingInfoFormOrderButtonLabel' | localize"
      [actionInProgressLabel]="'mailingInfoFormOrderInProgressButtonLabel' | localize"
      [buttonType]="'button'"
      [confirmationLabel]="'mailingInfoFormOrderConfirmationButtonLabel' | localize"
      [buttonDisabled]="!orderingEnabled"
      (buttonClick)="orderDevice()"
      [buttonState]="actionButtonState"
      [testId]="'order-device-button'">
    </app-pre-confirm-action-button>

    <span
      *ngIf="shouldShowValidationError()"
      data-testid="order-device-validation-error-message"
      class="alert alert-danger ms-2">
        {{ "orderDeviceValidationErrorMessage" | localize }}
      </span>

  </div>
</div>
