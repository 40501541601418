import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FoundMeasurement} from "../found-measurement";
import {ApiClient} from "../../../tools/api-client";
import {map} from "rxjs/operators";
import {recursiveNullToUndefined} from "../../../tools/recursive-null-to-undefined";

@Injectable()
export class RevertFromDoneService {

  constructor(private readonly apiClient: ApiClient) {
  }

  revertFromDone(measurementId: number): Observable<FoundMeasurement> {
    return this.apiClient.put<FoundMeasurement>(`api/measurement/${measurementId}/revert-from-done`)
      .pipe(map(recursiveNullToUndefined));
  }
}
