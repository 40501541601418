import {Injectable} from "@angular/core";

@Injectable()
export class MeasurementCodeGenerator {
  generateCode(): string {
    return `${this.generateRandomString()}${Math.floor(Date.now() / 1000)}`;
  }

  private generateRandomString(length = 4) {
    return Array.from({length}, this.getRandomLetter).join("");
  }

  private getRandomLetter() {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return letters[Math.floor(Math.random() * letters.length)];
  }

}
