<div class="container">

  <form>
    <div class="row mb-3">
      <label for="chain-name-input" class="col-sm-2 col-form-label">{{ 'addChainFormNameLabel' | localize }}</label>
      <div class="col-sm-10">
        <input
          type="text"
          class="form-control"
          id="chain-name-input"
          data-testId="chain-name"
          [(ngModel)]="chainName"
          [ngModelOptions]="{standalone: true}"
          (input)="updateName($event)">
      </div>
    </div>
    <div>
      <button
        type="submit"
        class="btn btn-primary"
        data-testId="add-chain-button"
        (click)="addChain()">
        {{ "addChainButtonLabel" | localize }}
      </button>
      <span data-testid="submit-error-message" *ngIf="!!errorMessage" class="alert alert-danger ms-2">
        {{ errorMessage | localize }}
      </span>
    </div>
  </form>

  <hr/>
  <h2>{{ 'chainsListTitle' | localize }}</h2>
  <div class="grid grid-striped">
    <div class="row" *ngFor="let chain of existingChains">
      <div class="col-11"><span>{{ chain.name }}</span></div>
      <div class="col-1">
        <button
          [attr.data-testid]="'edit-chain-button-' + chain.name"
          class="btn btn-sm btn-secondary"
          [title]="'chainEditButton' | localize"
          (click)="editChain(chain.id)"
        >
          <i class="fa fa-edit"></i>
        </button>
      </div>
    </div>
  </div>
</div>

