export interface AuthenticatedUser {
  username: string;
  role: UserRole;
  token: string;
  otpRequired?: boolean;
  language: string;
  isChainDoctor: boolean;
  nurseSettings?: NurseSettings;

}

export interface NurseSettings {
  allowedLocalMeasurementTypes: boolean;
  allowedHomeMeasurementTypes: boolean;
  hasOperatorHospitals: boolean;
  allowedSsnInput: boolean;
  allowedComments: boolean;
}

export const ALL_USER_ROLES = ["NURSE", "DATA_OFFICER", "DOCTOR", "HMO"] as const;
type UserRoleTuple = typeof ALL_USER_ROLES;
export type UserRole = UserRoleTuple[number]; // union type

export function isValidAuthenticatedUser(user: AuthenticatedUser) {
  return !!user && !!user.username && !!user.role && !!user.token;
}
